@import "variables";@import "mixins";
.body {
	width: 100%;

	&__content {
		margin: 0 auto;
		width: 100%;

		// max-width: $max-stage-width;
		position: relative;
		overflow: hidden;
	}

	&.rtl {
		direction: rtl;
	}
}
