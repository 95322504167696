@import "variables";@import "mixins";
.toaster {
	&__toaster-container {
		position: fixed;
		bottom: -100px;
		left: 50%;
		transform: translateX(-50%);
		transition: bottom 0.3s ease-in-out;
	}

	&__toaster-container.show {
		bottom: 50px;
	}

	&__toaster {
		background-color: $color-white;
		color: $color-white;
		border-radius: 25px;
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
		display: flex;
		align-items: center;
		overflow: hidden;
		border-radius: 10px;
		border: 1px solid #ffc72f;
		min-width: 290px;
	}

	&__toaster p {
		margin: 0;
		font-size: 14px;
		color: #3b3b3b;
		padding: 10px 20px;
	}
	&__toaster-icon {
		width: 40px;
		height: 44px;
		background: #fff1cb;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	@media (max-width: 295px) {
		&__toaster p {
			font-size: 12px;
		}
		&__toaster-icon {
			height: 50px;
		}
	}
}
