@import "variables";@import "mixins";
.button-fixed {
	@include btnText;
	color: $color-white;
	position: absolute;
	bottom: 30px;
	left: 0;
	cursor: pointer;
	right: 0;
	background-color: $color-green-600;
	border-radius: $border-radius-default;
	border: none;
	box-shadow: $button-drop-shadow;
	transition: $transition-element;
	height: 60px;
	line-height: 60px;
	width: calc(100% - 40px);
	max-width: 600px;
	margin: 0 auto;
	z-index: 100;
	@include md {
		bottom: 50px;
	}
	&:focus {
		box-shadow: $button-drop-shadow;
		outline: 0;
	}
	&:hover {
		color: $color-white;
		background-color: darken($color-green-600, 3%);
	}
	&:active {
		background-color: darken($color-green-600, 5%);
		box-shadow: $button-drop-shadow-hover;
		outline: none;
		border: none;
		transform: $transform-depress;
	}
	// State: loading
	&.loading {
		pointer-events: none;
		color: transparent !important;
	}
	&.loading::after {
		-webkit-animation: spin-loader 1000ms infinite linear;
		animation: spin-loader 1000ms infinite linear;
		border: 2px solid $color-white;
		border-radius: 290486px;
		border-right-color: transparent;
		border-top-color: transparent;
		content: '';
		display: block;
		height: 16px;
		position: relative;
		width: 16px;
		left: 50%;
		margin-left: -8px;
		margin-top: -8px;
		position: absolute;
		top: 50%;
		position: absolute !important;
	}
	&.loading::after,
	&.loading::before {
		box-sizing: inherit;
	}

	&.disabled {
		opacity: 0.5;
		pointer-events: none;
	}
}

@-webkit-keyframes spin-loader {
	from {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

@keyframes spin-loader {
	from {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}
