@import "variables";@import "mixins";
.preview-checker {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: center;
	height: 100vh;
	margin: auto;

	&__container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
	}
	&__left-side-container {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	&__title {
		margin: 0 auto 8px;
		display: flex;
		font-size: 20px;
		font-weight: 600;
		line-height: 30px;
		text-align: center;

		&:focus,
		&:focus-visible {
			outline: none;
		}
	}
	&__content {
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin-bottom: 32px;
		> span {
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 21px;
			text-align: center;
			color: $color-grey-6c;
			max-width: 460px;
		}
	}
	&__footer {
		display: flex;
		flex-direction: column;
		width: 100%;
		justify-content: flex-end;
		align-items: center;
		gap: 8px;
		margin: 32px auto 0;

		& > button {
			max-width: 444px;
		}
	}

	&__button {
		padding: 8px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		border-radius: 6px;
		max-width: 444px;
		&:hover {
			background-color: #f5f5f5;
		}
	}

	&__button-primary {
		padding: 8px;
		width: 100%;
		max-width: 444px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		background-color: $color-green-600;
		border-radius: 6px;
		&:hover,
		&:focus {
			background-color: darken($color-green-600, 3%);
		}
	}
	&__button-deny {
		padding: 8px;
		width: 100%;
		max-width: 444px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		background-color: #f5f6f8;
		border-radius: 6px;
		&:hover {
			background-color: #f5f6f8;
		}
	}

	&__reject {
		font-size: 14px;
		font-weight: 600;
		line-height: 21px;
		text-align: left;
		box-shadow: none;
		color: $color-grey-6c;
	}
	&__allow {
		font-size: 14px;
		font-weight: 600;
		line-height: 21px;
		text-align: left;
		color: $color-white;
	}
	&__deny {
		font-size: 14px;
		font-weight: 600;
		line-height: 21px;
		text-align: left;
		color: $color-grey-darker;
	}

	&__square-wrapper {
		flex: 1;
		aspect-ratio: 1 / 1;
		overflow: hidden;
		border-radius: 12px;
		min-width: 300px;
		max-width: 600px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;

		@media (max-width: 490px) {
			max-width: 300px;
		}

		&:last-of-type {
			@media (max-width: 490px) {
				aspect-ratio: auto;
			}
		}
	}

	&__active-border {
		border: 5px solid #5bc397;
		border-radius: 12px;
		position: absolute;
		top: -2px;
		left: -2px;
		width: 99%;
		height: 99%;
		z-index: 2;
	}

	&__preview-camera {
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		background: $color-grey-transparent-bg;
		border-radius: 12px;
	}
	&__canvas-video {
		width: 100%;
		height: 100%;
		-webkit-transition: opacity 1s ease-in-out;
		-moz-transition: opacity 1s ease-in-out;
		-o-transition: opacity 1s ease-in-out;
		transition: opacity 1s ease-in-out;
		position: absolute;
	}

	&__video-context {
		min-width: 100%;
		min-height: 100%;
		object-fit: cover;
		transform: scaleX(-1);

		&.mirrored {
			transform: scaleX(1);
		}
	}

	&__no-camera-container {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 600px;
		border-radius: 12px;
		border: 1px solid $color-grey-E8;
		background: $color-grey-FA;
	}
	&__no-camera-img-container {
		width: 97px;
		height: 97px;
		border-radius: 65.5px;
		background: $color-grey-transparent-bg;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&__no-camera-img {
		width: 31px;
		height: 31px;
	}

	&__no-access {
		position: absolute;
		padding: 16px 21px;
		top: 0;
		left: 0;
		z-index: 2;
		display: flex;
		flex-wrap: wrap;
		gap: 14px;
	}

	&__no-access-container {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		padding: 4px 5px;
		gap: 4px;
		position: relative;
		border-radius: 4px;
		background: $color-grey-transparent-bg;
	}
	&__no-camera-text {
		display: inline-flex;
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: 18px;
		color: $color-grey-6c;
	}
	&__no-access-icon {
		display: inline-flex;
	}
	&__no-access-warning {
		position: absolute;
		right: -5px;
		bottom: -5px;
	}
	&__list-rules {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 12px;
	}
	&__rule-item {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 10px;
		border-radius: 8px;
		border: 1px solid $color-grey-E8;
		background: $color-white;
		padding: 16px;
		max-width: 426px;
	}
	&__rule-text {
		font-size: 14px;
		line-height: 21px;
		text-align: left;
	}
	&__rule-image {
		width: 32px;
	}
	&__preview-container {
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;
	}
	&__preview-container-mobile {
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;
		flex-direction: column;
		max-width: 100%;
		overflow-y: scroll;
	}

	&__frequency-bar {
		display: flex;
		width: 1.5%;
		min-height: 17%;
		background-color: #5bc397;
		border-radius: 2px;
	}
	&__audio-bars {
		position: absolute;
		right: 32%;
		bottom: 5%;
		width: 33%;
		height: 8%;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		gap: 1.5%;
		z-index: 9;
	}

	&__preview-camera {
		position: relative;
	}

	@media (max-width: 1120px) {
		&__container {
			margin-right: 45px;
			margin-left: 45px;
		}
		&__left-side-container {
			height: 400px;
			margin-top: 32px;
		}
	}

	@media (max-width: 770px) {
		max-width: 100%;
		flex-direction: column-reverse;
		justify-content: flex-end;
		margin-top: 40px;

		&__container {
			max-width: 90%;
			margin-top: 32px;
		}
		&__active-border {
			width: 98%;
			height: 98%;
		}
		&__header {
			margin-bottom: 12px;
		}
		&__footer {
			& > button {
				max-width: 85vw;
			}
		}
		&__button {
			max-width: 80vw;
		}
		&__button-primary {
			max-width: 85vw;
		}
		&__no-camera-container {
			height: 300px;
		}
		&__no-access {
			padding: 10px;
			gap: 8px;
		}
		&__list-rules {
			margin-bottom: 150px;
		}
		&__footer {
			position: fixed;
			height: 90px;
			bottom: 0px;
			left: 0px;
			background-color: $color-white;
		}
	}

	@media (max-width: 490px) {
		max-width: 100%;
		flex-direction: column-reverse;
		justify-content: flex-end;
		margin-top: 40px;

		&__container {
			max-width: 90%;
			margin-top: 32px;
		}

		&__left-side-container {
			height: 300px !important;
			margin-top: 40px;
		}
		&__active-border {
			width: 98%;
			height: 98%;
		}
		&__header {
			margin-bottom: 12px;
		}
		&__button {
			max-width: 80vw;
		}
		&__button-primary {
			max-width: 85vw;
		}
		&__no-camera-container {
			height: 300px;
		}
		&__no-access {
			padding: 10px;
			gap: 8px;
		}
		&__list-rules {
			margin-bottom: 150px;
		}
		&__footer {
			position: fixed;
			height: 90px;
			bottom: 0px;
			left: 0px;
			right: 0px;
			background-color: $color-white;
		}
	}

	@media (max-width: 295px) {
		&__rule-item {
			padding: 10px;
			font-size: 12px;
		}
		&__active-border {
			width: 95%;
			height: 98%;
			left: 2px;
		}
	}
}
