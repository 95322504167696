@import "variables";@import "mixins";
.progress-bar {
	&__container {
		position: relative;
		width: 100%;
		border-radius: 10px;
		margin-bottom: 20px;
	}

	&__bar-info {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		gap: 12px;
		margin-bottom: 16px;
	}

	&__thumbnail-wrapper {
		display: flex;
		align-items: center;
		gap: 8px;

		img {
			width: 40px;
			height: 40px;
			object-fit: cover;
			border-radius: 4px;
		}

		p {
			font-family: 'Poppins';
			font-style: normal;
			font-size: 14px;
			line-height: 1.5;
			font-weight: 400;
			margin: 0;
		}
	}

	&__indicator {
		position: relative;
		height: 3px;
		align-self: flex-end;
		flex: 1 1 auto;
		border-radius: 10px;
		background-color: #dadada;
	}

	&__bar {
		position: relative;
		transition: all ease-in-out 1s;
		min-width: 3px;
		max-width: 100%;
		height: 100%;
		border-bottom-left-radius: 10px;
		border-top-left-radius: 10px;
		background: #2bb681;
	}

	&__bar-percent {
		font-family: 'Poppins';
		font-style: normal;
		font-size: 10px;
		text-align: right;
		width: auto;
		right: 0px;
		bottom: 4px;
		margin-left: auto;
		color: $color-black;
		transition: left ease-in-out 1s;
	}

	@media (max-width: 1280px) {
		&__bar-info {
			margin-bottom: 8px;
		}
	}
}
