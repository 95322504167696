@import "variables";@import "mixins";
.question-emoji-select.custom-radio-group.radio-group {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	gap: 4px;
	margin: auto;
	margin-top: 5px;

	@media only screen and (max-width: 400px) {
		max-width: 240px;
	}

	@media only screen and (min-width: 401px) {
		max-width: 360px;
	}

	// Select Container
	& > .radio-button {
		border-radius: $border-radius-default;
		transition: $transition-element;
		background: $color-white;
		outline: 0;
		margin: 0;
		max-width: 56px;
		height: 56px;
		padding: 0px;

		&:active {
			transform: $transform-depress;
			box-shadow: $button-drop-shadow-hover;
		}
	}

	// The shape that the user clicks on
	// Actual Checkbox shape
	& > .radio-button > .radio-button--checkbox {
		display: none;
		// Make sure the shape doesn't get crushed
		min-width: 10px;
		min-height: 10px;
	}
}
