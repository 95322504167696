@import "variables";@import "mixins";
.questionnaire-textarea {
  padding: 16px;
  width: 100%;
  background: $color-white;
  line-height: 1.4;
  border: 1px solid #dadada;
  border-radius: $border-radius-default !important;
  box-sizing: border-box;
  transition: box-shadow 0.1s ease 0s;
  -webkit-user-select: text;
  resize: none;

  // Hide scrollbar for Chrome, Safari and Opera
  &::-webkit-scrollbar {
    display: none;
  }

  // Hide scrollbar for IE, Edge and Firefox
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox

  &:focus {
    outline: none;
    border: 1px solid #01c39d;
  }
}