@import "variables";@import "mixins";
@import "./src/styles/_variables.scss";

.loader-0 {
	width: 40px;
	height: 40px;
	border: 6px solid $color-primary;
	border-color: $color-primary transparent $color-primary transparent;
	border-radius: 30px;
	animation: loader-loading 1.0s linear infinite;

	&__centered {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

@keyframes loader-loading {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
