@import "variables";@import "mixins";
.animated-blob {
	animation: pulsate 1.5s infinite alternate;
}

@keyframes pulsate {
	0% {
		transform: scale(0.9) rotate(-15deg);
		animation-timing-function: cubic-bezier(0.5, 0, 1, 1); /* speed up */
	}

	50% {
		transform: scale(1) rotate(0);
		animation-timing-function: cubic-bezier(0, 0, 0.75, 1); /* slow down */
	}

	100% {
		transform: scale(0.9) rotate(15deg);
	}
}
