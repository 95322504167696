@import "variables";@import "mixins";
.scroll-container__inner--rtl {
	max-width: 1280px !important;
}
.video-interactor {
	display: flex;
	align-items: stretch;
	width: 100%;
	justify-content: center;
	gap: 12px;

	@media (max-width: 770px) {
		flex-direction: column-reverse;
		align-items: center;
	}

	* {
		box-sizing: border-box;
	}

	&__square-wrapper {
		flex: 1;
		aspect-ratio: 1 / 1;
		overflow: hidden;
		border-radius: 12px;
		min-width: 300px;
		max-width: 600px;
		position: relative;

		@media (max-width: 768px) {
			max-width: 300px;
		}

		&:last-of-type {
			@media (max-width: 768px) {
				aspect-ratio: auto;
				margin-top: 48px;
			}
		}
	}

	&__button-flex {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;
	}
	&__video-controls {
		margin-bottom: 8px;
	}
	&__video-player,
	&__recorded-player {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	&__live-player {
		height: 418px;
		border-radius: 4px;
	}
	&__recorded-player video {
		height: 418px;
	}
	&__info {
		font-size: 14px;
		font-weight: 400;
		margin-top: 8px;
		margin-bottom: 24px;
		text-align: justify;
	}

	&__icon {
		width: 24px;
	}
	&__button:hover {
		box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	}
	&__button {
		margin-top: 6px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
		width: 160px;
		padding: 8px;
		height: 32px;
		border-radius: 6px;
		> span {
			margin-left: 8px;
		}
	}
	&__not-allowed {
		margin-top: 6px;
		font-size: 14px;
		font-weight: 400;
		color: gray;
		cursor: pointer;
	}

	&__video-transcription {
		margin-top: 30px;
		margin-bottom: 30px;
		font-size: 16px;
		text-align: justify;
		text-wrap: wrap;
	}

	&__video-status {
		margin-top: 8px;
		margin-bottom: 8px;
		font-size: 14px;
		font-weight: 400;
		color: green;
	}

	&__image-overlay-mobile {
		position: absolute;
		display: flex;
		-webkit-transition: opacity 1s ease-in-out;
		-moz-transition: opacity 1s ease-in-out;
		-o-transition: opacity 1s ease-in-out;
		transition: opacity 1s ease-in-out;
		border-radius: 12px;
		overflow: hidden;
		&:hover {
			background: rgba(0, 0, 0, 0.41);
		}
	}

	&__hover-overlay {
		opacity: 0;
		background: rgba(0, 0, 0, 0.41);
		width: 100%;
		height: calc(100% - 24px);
		position: absolute;
		top: 0px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 8px;
	}
	&__image-overlay-text {
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
		color: #6c757d;
		margin-top: 24px;
	}

	&__image-overlay {
		display: flex;
		-webkit-transition: opacity 1s ease-in-out;
		-moz-transition: opacity 1s ease-in-out;
		-o-transition: opacity 1s ease-in-out;
		transition: opacity 1s ease-in-out;
		border-radius: 12px;
		overflow: hidden;
		justify-content: center;
		align-items: center;
		max-width: 500px;
		margin-top: 12px;
		position: relative;
		flex-direction: column;
		&:hover {
			.video-interactor__hover-overlay {
				opacity: 1;
			}
		}
		> img {
			max-width: 300px;
			max-height: 200px;
			width: 100%;
			height: 100%;
			object-fit: contain;
			overflow: hidden;
			margin-top: 15px;
		}
	}
	&__video-transcript {
		width: 100%;
		display: flex;
		justify-content: center;
	}
	&__canvas-video {
		-webkit-transition: opacity 1s ease-in-out;
		-moz-transition: opacity 1s ease-in-out;
		-o-transition: opacity 1s ease-in-out;
		transition: opacity 1s ease-in-out;
		border-radius: 12px;
	}
	&__thinking-animation {
		height: 315px;
		margin-bottom: 65px;
	}
	&__ai-status-parent {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;

		display: flex;
		flex-direction: column;
	}

	&__flip-camera-container {
		position: absolute;
		top: 45px;
		right: 45px;
		cursor: pointer;
	}
	&__pause-container {
		position: absolute;
		top: 45px;
		left: 45px;
		cursor: pointer;
	}
	&__pause-container-desktop {
		position: absolute;
		top: 25px;
		left: 45px;
		cursor: pointer;
	}

	&_video-player {
		background-color: $color-white;
		border-radius: 8px;
	}

	&__chat-mobile {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 300px;
		height: 300px;
		flex-direction: column;
	}
	&__transcript {
		font-size: 32px;
		font-weight: 400;
		line-height: 44px;
		text-align: center;
		width: 90%;
	}
	&__finish-talking {
		font-size: 18px;
		font-weight: 400;
		line-height: 27px;
		text-align: center;
		color: #6c757d;
	}

	&__welcome {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;
		.loader-0 {
			margin-bottom: 42px;
		}
	}
	&__welcome-container {
		text-align: center;
		max-width: 421px;
		min-height: 174px;
		align-items: center;
		display: flex;
		flex-direction: column;
		> button {
			max-width: 372px;
		}
	}
	&__welcome-content {
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		text-align: center;
		margin-top: 0px;
		max-width: 340px;
	}
	&__welcome-title {
		font-size: 25px;
		font-weight: 600;
		line-height: 38px;
		text-align: center;
		margin-top: 0px;
	}

	&__success-image {
		margin-bottom: 40px;
	}

	&__list-rules {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		background-color: #e6f2f5;
		border-radius: 8px;
		padding: 16px;
		gap: 12px;
		margin-top: 32px;
	}
	&__rule-item {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 10px;
	}
	&__rule-text {
		font-family: Poppins;
		font-size: 14px;
		line-height: 21px;
		text-align: left;
	}
	&__rule-image {
		margin-top: 2px;
		width: 20px;
		height: 20px;
	}

	&__modal-image {
		display: none;
		position: fixed;
		z-index: 1;
		left: 0;
		top: 152px;
		width: 100%;
		height: 604px;
		overflow: auto;
		background-color: #fff;
		justify-content: center;
		z-index: 9;
	}
	&__modal-image-content {
		margin: auto;
		display: block;
		height: 500px;
	}

	&__modal-image-content {
		-webkit-animation-name: zoom;
		-webkit-animation-duration: 0.6s;
		animation-name: zoom;
		animation-duration: 0.6s;
	}

	&__modal-image-content.out {
		animation-name: zoom-out;
		animation-duration: 0.6s;
	}
	&__modal-image-close {
		position: absolute;
		right: 20px;
		top: 12px;
		cursor: pointer;
	}

	&__modal-insider {
		display: flex;
		padding: 24px;
		background-color: #f4f4f4;
		position: relative;
		justify-content: center;
		align-items: center;
		max-width: 1220px;
		width: 100%;
		border-radius: 8px;
		z-index: 10;
	}

	@-webkit-keyframes zoom {
		from {
			-webkit-transform: scale(1);
		}
		to {
			-webkit-transform: scale(2);
		}
	}

	@keyframes zoom {
		from {
			transform: scale(0.4);
		}
		to {
			transform: scale(1);
		}
	}

	@keyframes zoom-out {
		from {
			transform: scale(1);
		}
		to {
			transform: scale(0);
		}
	}
}
