@import "variables";@import "mixins";
.scroll-container {
	margin: 0 auto;
	width: 100%;
	overflow-y: auto;
	position: relative;
	// Set overflow to enable scrollbars
	overflow-x: hidden;
	overflow-y: auto;
	height: 100%;

	// Hide scrollbar for Chrome, Safari and Opera
	&::-webkit-scrollbar {
		display: none;
	}

	// Hide scrollbar for IE, Edge and Firefox
	-ms-overflow-style: none; // IE and Edge
	scrollbar-width: none; // Firefox

	&__inner {
		max-width: $max-stage-width;
		margin: 0 auto;
		padding-top: 10px;
	}
}