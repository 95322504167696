@import "variables";@import "mixins";
.scroll-container.ios-mobile,
.scroll-container.android-mobile {
    .open-ended-pledge-container {
        height: 85vh;
        min-height: 85vh;
        max-height: 85vh;
        max-height: -moz-available;
        max-height: -webkit-fill-available;
    }
}

.open-ended-pledge-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;

    &__button {
        padding: 20px;
    }

    &__questions {
        padding: 20px;
        white-space: pre-line;
    }

    &__inline-button {
        padding: 15px 0px;
        width: 100%;
    }

    &__inline-button-container {
        display: flex;
        justify-content: flex-end;
        display: -webkit-flex;
        -webkit-justify-content: flex-end;
    }
}