@import "variables";@import "mixins";
.device-preview-header+.scroll-container {
	.product-card-item {
		margin-top: -40px;
	}
}

.product-card-stack {
	// display: grid;
	// grid-template-columns: 100%;
	align-items: center;
	width: 100%;

	height: calc(100vh - 100px);
	max-height: -moz-available;
	max-height: -webkit-fill-available;


	justify-content: center;
	// text-align: center;
	overflow-y: visible;
	position: relative;

	.choice-indicator-overlay-container {
		max-width: 600px;
		overflow-x: hidden;
		height: 100%;
		position: absolute;
		top: 0px;
		width: 100%;
		z-index: 999;
	}

	.choice-indicator-overlay {
		display: flex;
		justify-content: center;
		position: absolute;
		width: 50px;
		height: 50px;
		z-index: 999;
		border-radius: 50px;
		opacity: 0;

		.choice-indicator-icon {
			position: absolute;
			width: 30px;
			height: 30px;
			top: calc(50% - 15px);
			left: calc(50% - 15px);
		}
	}
}

$footerHeight: 100px;

.product-card-item {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	cursor: default;
	grid-column: 1/2;
	grid-row: 1/2;
	will-change: transform;

	touch-action: none;
	-moz-user-select: none;
	-webkit-user-drag: none;
	user-select: none;

	img {
		width: 100%;
	}

	*:focus {
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	*::selection {
		background: transparent;
	}

	*::-moz-selection {
		background: transparent;
	}

	.card-view-product {
		box-shadow: none;
		outline: 1px solid #bababac7;

		&.textOnly .stack-card-section-text-container {
			display: flex;
			justify-content: center;
			height: 100%;
		}
	}

}

.idea-swipe-tour-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	// background-color: #0000004d;
	z-index: 999;

}

.swipe-gesture-icon-container {
	position: absolute;
	transition: all 1s ease;
	top: calc(50% - 30px);
	left: calc(50% - 15px);
	width: 30px;
	height: 30px;
}

.swipe-gesture-icon {
	position: absolute;
	left: 0;
	top: 0;
	width: 30px;
	height: 30px;

	&:before {
		content: '';
		position: relative;
		display: block;
		width: 300%;
		height: 300%;
		box-sizing: border-box;
		margin-left: -100%;
		margin-top: -100%;
		border-radius: 45px;
		background-color: #00000020;
		animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
	}

	&:after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		width: 100%;
		height: 100%;
		background-color: white;
		border-radius: 15px;
		box-shadow: 0 0 8px #0000004d;
		animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
	}

}

@keyframes pulse-ring {
	0% {
		transform: scale(.33);
	}

	80%,
	100% {
		opacity: 0;
	}
}

@keyframes pulse-dot {
	0% {
		transform: scale(.8);
	}

	50% {
		transform: scale(1);
	}

	100% {
		transform: scale(.8);
	}
}


.bot-block-barrier {
	position: absolute;
	display: flex;
	opacity: 0;

	&.show {
		opacity: 1;
	}

	&.hide {
		display: none;
		pointer-events: none;
		opacity: 0;
	}

	z-index: 9999;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	overscroll-behavior: none;
	overflow: hidden;
	overscroll-behavior-block: contain;
	overscroll-behavior-inline: contain;

	.disabled-icon {
		display: block;
		position: relative;
		width: 40px;
		height: 40px;
		margin: 10px 0px 0px 30px;
		background-color: #bababa;
		border-radius: 50%;

		svg.gesture {
			position: absolute;
			width: 20px;
			height: 20px;
			top: calc(50% - 10px);
			left: calc(50% - 10px);
		}
	}

	svg.countdown {
		position: absolute;
		top: 0;
		left: 0;
		width: 40px;
		height: 40px;
	}

	svg.countdown circle {
		stroke-dasharray: 200;
		stroke-dashoffset: 0px;
		stroke-linecap: round;
		stroke-width: 3px;
		stroke: white;
		fill: none;
		animation: countdown 1s linear infinite forwards;
		transform: rotateZ(-90deg) translateX(-40px);
	}
}


@keyframes countdown {
	from {
		stroke-dashoffset: 0px;
	}

	to {
		stroke-dashoffset: 250px;
	}
}