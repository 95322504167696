@import "variables";@import "mixins";
.video-interactor-recorder {
	overflow: hidden;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	background: #f4f4f4;
	border-radius: 12px;
	position: relative;

	&__audio-feedback-circle {
		position: absolute;
		right: 32%;
		bottom: 5%;
		width: 33%;
		height: 8%;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		gap: 1.5%;
		padding: 1px;
		z-index: 9;
	}

	&__frequency-bar {
		display: flex;
		width: 17%;
		min-height: 17%;
		background-color: #5bc397;
		border-radius: 4px;
	}

	&__active-border {
		border: 5px solid #5bc397;
		border-radius: 12px;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}

	&__image-overlay-mobile {
		position: absolute;
		display: flex;
		-webkit-transition: opacity 1s ease-in-out;
		-moz-transition: opacity 1s ease-in-out;
		-o-transition: opacity 1s ease-in-out;
		transition: opacity 1s ease-in-out;
		border-radius: 12px;
		overflow: hidden;
		width: 100%;
		height: 100%;

		& img {
			object-fit: cover;
			overflow: hidden;
		}

		&:hover {
			background: rgba(0, 0, 0, 0.41);
		}
	}

	&__canvas-video {
		width: 100%;
		height: 100%;
		-webkit-transition: opacity 1s ease-in-out;
		-moz-transition: opacity 1s ease-in-out;
		-o-transition: opacity 1s ease-in-out;
		transition: opacity 1s ease-in-out;
		position: absolute;
	}

	&__video-context {
		min-width: 100%;
		min-height: 100%;
		object-fit: cover;
		transform: scaleX(-1);

		&.mirrored {
			transform: scaleX(1);
		}
	}

	&__no-camera-container {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		border-radius: 12px;
		border: 1px solid #e8e8e8;
		background: #fafafa;
	}
	&__no-camera-img-container {
		width: 97px;
		height: 97px;
		border-radius: 65.5px;
		background: #f4f4f4;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&__no-camera-img {
		width: 31px;
		height: 31px;
	}
}
