@import "variables";@import "mixins";
.ranked-reset-button {
  display: flex;
  justify-content: center;
  display: -webkit-flex;
  -webkit-justify-content: center;

  &__inline-button {
		padding: 15px 0px;
		width: 100%;
	}

  &__ranked-reset-button {
		padding: 5px 0;
		margin-bottom: 20px;

		button {
			color: $color-green-600;
			background: none;
			box-shadow: none;
			padding: 0;
			height: fit-content;
		}

		button:hover {
			background: none;
			color: $color-green-600;
		}
	}

}