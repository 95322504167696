.text-ai-product-intro,
.text-ai-product-description {
    &__footer {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-bottom: 30px;
        > div {
            width: 100%;
            padding: 30px 0;
            font-size: 12px;
            font-weight: bold;
            text-align: center;
        }
        .basic-button {
            border-radius: 20px;
            width: auto;
            height: auto;
            margin: 0 auto;
            text-transform: capitalize;
            background-color: #4bdf93;
            padding: 15px 15px;
            font-size: 12px;
            box-shadow: none;
        }
    }
    &__content {
        padding: 15px;
    }
    &__card {
        position: relative;
        width: 100%;
        margin: 0 auto;
        background: white;
        overflow: hidden;
        -webkit-border-radius: 6px 6px 6px 6px;
        border-radius: 6px 6px 6px 6px;
        box-shadow: 0 3px 13px 4px rgba(0, 0, 0, 0.1);
    }
    &__image {
        display: flex;
        max-height: 30vh;
        margin: 0 auto;
    }
    &__text {
        padding: 15px 15px 0 15px;
    }
    &__title {
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 8px;
    }
    &__subtitle {
        margin: 0px;
        margin-bottom: 10px;
        color: darkgrey;
        font-weight: 100;
    }
    svg.svg-inline--fa {
        background: white;
        margin: 0;
        width: 100%;
        padding: 8px 0;
        &.show {
            bottom: 0%;
            transform: rotate(180deg);
        }
    }
}