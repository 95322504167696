@import "variables";@import "mixins";
.video-interactor-transcript {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 12px;
	background-color: $color-white;
	aspect-ratio: 1 / 1;
	position: relative;

	&__listening-span {
		font-size: 15px;
		font-weight: 400;
		color: #2cd385;
		position: absolute;
		bottom: 3px;
	}

	&__ptt-button {
		position: absolute;
		top: 5px;
		right: 5px;
		width: 40px;
		height: 40px;
	}
	&__ptt-button.active {
		opacity: 0.5;
	}

	&__horizontal-half {
		height: 50%;
		width: 100%;
		flex: 1;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;

		&.upper {
			transform: translate(0, 20%);
		}

		&.lower-frequency {
			transform: translate(0, -30%);
		}

		&.lower-processing {
			transform: translate(0, -20%);
		}
	}

	&__frequency-container {
		display: flex;
		height: 60%;
		width: 50%;
		align-items: center;
		justify-content: center;
		gap: 3%;
	}

	&__frequency-bar {
		display: flex;
		width: 22%;
		min-height: 36.6667%;
		background-color: #5bc397;
		border-radius: 9999px;
	}

	&__thinking-animation {
		height: 315px;
		margin-bottom: 65px;
	}

	&__action-label {
		font-size: 18px;
		font-weight: 400;
		line-height: 1.5;
		text-align: center;
		color: #6c757d;
	}

	&__transcript {
		padding: 16px;
		font-size: 32px;
		font-weight: 400;
		line-height: 1.5;
		text-align: center;
		width: 90%;
	}

	&__hover-overlay {
		opacity: 0;
		background: rgba(0, 0, 0, 0.41);
		width: 100%;
		height: calc(100% - 24px);
		position: absolute;
		top: 0px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 8px;
	}

	&__image-overlay {
		display: flex;
		-webkit-transition: opacity 1s ease-in-out;
		-moz-transition: opacity 1s ease-in-out;
		-o-transition: opacity 1s ease-in-out;
		transition: opacity 1s ease-in-out;
		border-radius: 12px;
		overflow: hidden;
		justify-content: center;
		align-items: center;
		max-width: 500px;
		margin-top: 12px;
		position: relative;
		flex-direction: column;

		&:hover {
			.video-interactor-transcript__hover-overlay {
				opacity: 1;
			}
		}
		> img {
			max-width: 300px;
			max-height: 200px;
			width: 100%;
			height: 100%;
			object-fit: contain;
			overflow: hidden;
			margin-top: 15px;
		}
	}

	&__image-overlay-text {
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
		color: #6c757d;
		margin-top: 24px;
	}

	&__modal-image {
		display: none;
		position: fixed;
		z-index: 1;
		left: 0;
		top: 152px;
		width: 100%;
		height: 604px;
		overflow: auto;
		background-color: #fff;
		justify-content: center;
		z-index: 10;
	}

	&__modal-image-content {
		margin: auto;
		display: block;
		height: 500px;
	}

	&__modal-image-content {
		-webkit-animation-name: zoom;
		-webkit-animation-duration: 0.6s;
		animation-name: zoom;
		animation-duration: 0.6s;
	}

	&__modal-image-content.out {
		animation-name: zoom-out;
		animation-duration: 0.6s;
	}

	&__modal-image-close {
		position: absolute;
		right: 20px;
		top: 12px;
		cursor: pointer;
	}

	&__modal-insider {
		display: flex;
		padding: 24px;
		background-color: #f4f4f4;
		position: relative;
		justify-content: center;
		align-items: center;
		max-width: 1220px;
		width: 100%;
		border-radius: 8px;
	}

	&__welcome-text {
		font-size: 17px;
		font-weight: 600;
		line-height: 26px;
		text-align: center;
		margin-bottom: 20px;
	}
	&__welcome-buttons-wrap {
		width: 220px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 12px;
		@media (max-width: 1280px) {
			margin-top: 45px;
		}
	}

	&__button-next {
		display: flex;
		height: 36px;
		justify-content: center;
		align-items: center;
		border-radius: 6px;
		background: #28b681;
		cursor: pointer;
		width: 220px;
		&:hover {
			background-color: #5bc397;
		}
	}
	&__next-text {
		color: #fff;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 21px;
	}

	&__skip-button {
		display: flex;
		height: 36px;
		padding: 8px 16px;
		justify-content: center;
		align-items: center;
		align-self: stretch;
		background: #fff;
		cursor: pointer;
		border-radius: 8px;
		&:hover {
			background-color: #f5f6f8;
		}
	}
	&__skip-reject {
		font-size: 14px;
		font-weight: 600;
		line-height: 21px;
		text-align: left;
		color: #6c757d;
	}

	@media (max-width: 1024px) {
		&__transcript {
			font-size: 28px;
		}
	}

	@media (max-width: 768px) {
		aspect-ratio: auto;
		background-color: transparent;

		&__horizontal-half {
			height: auto;
			padding: 16px;

			&.upper {
				transform: translate(0, 0);
			}

			&.lower-frequency {
				transform: translate(0, 0);
			}

			&.lower-processing {
				transform: translate(0, 0);
			}
		}

		&__frequency-container {
			height: 90px;
		}

		&__action-label {
			font-size: 16px;
		}

		&__transcript {
			font-size: 24px;
		}
	}

	@media (max-width: 440px) {
		&__thinking-animation {
			height: 230px;
			margin-bottom: 0px;
		}

		&__action-label {
			font-size: 14px;
		}

		&__transcript {
			font-size: 20px;
		}
	}
}
