@import "variables";@import "mixins";
$optionPaddingY: 12px;
$optionPaddingX: 12px;

.ranked-option {
	touch-action: manipulation;
	padding: 12px 8px 12px 12px !important; // Overriding inline styles from react-radio-button
	min-width: 100px;
	min-height: 24px;
	height: 32px;
	border: 1px solid $color-grey-E8 !important; // Overriding inline styles from react-radio-button
	border-radius: 4px !important; // Overriding inline styles from react-radio-button
	background: $color-white;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	position: relative;
	cursor: grab;

	&__wrapper {
		position: relative;
		margin: 0px 2px 8px 2px;
		border-radius: 4px;
	}

	&[style*='color: rgb(40, 182, 129)'] {
		background: #e3f4ec;
	}

	&__left {
		display: flex;
		align-items: center;
		gap: 8px;
		flex: 1;
		width: 70%;

		p {
			flex: 1;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			margin: 0;
		}
	}

	&__position-change-arrows {
		display: flex;
		align-items: center;
		gap: 10px;
		position: absolute;
		top: 0;
		right: 1px;
		bottom: 0;
		// margin-right: -18px;
		transition: padding-right 0.25s ease;
		padding-right: 0;

		&.extended {
			padding-right: 66px;
		}

		&.hidden {
			display: none;
		}
	}

	&:active {
		background: $color-green-very-light;
	}

	&__ranked-full-overlay {
		position: absolute;
		height: 100%;
		width: 100%;
		border-radius: 4px;
		background: rgba(59, 59, 59, 0.65);
		color: $color-white;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		opacity: 0;
		z-index: -1;
	}

	&__overlay-active {
		opacity: 1;
		z-index: 1;
	}

	&__overlay {
		cursor: grabbing;
	}

	&__remove-option-button {
		opacity: 0;
		width: 0;
		height: 56px;
		padding: 0 8px;
		align-items: center;
		overflow-x: hidden;
		border: none;
		position: absolute;
		right: 0;
		top: 1px;
		bottom: 1px;
		background: $color-red-superlight;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
		cursor: pointer;
		transition: all 0.25s ease;

		&.visible {
			width: 56px;
			opacity: 1;
		}
	}

	&__up-arrow,
	&__down-arrow {
		padding: 0;
		cursor: pointer;
		border: 0;
		background-color: transparent;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 34px;
		height: 34px;
	}
}

.ranked-option__is-dragging {
	backdrop-filter: blur(4px);
	opacity: 0.2;
}

.ranked-option__is-ranked {
	border: 1px solid $color-primary !important;
}

.ranked-option__overlay {
	background: $color-green-very-light;
}

.ranked-option__extend-width {
	animation: slideIn 0.25s ease forwards;
}

.ranked-option__exit-animation {
	animation: slideOut 0.25s ease;
}

@keyframes slideOut {
	from {
		width: calc(100% + 32px);
		transform: translateX(-56px);
	}
	to {
		width: calc(100% - 24px);
		transform: translateX(0);
	}
}

@keyframes slideIn {
	from {
		width: 100%;
		transform: translateX(0);
	}
	to {
		width: calc(100% + 52px);
		transform: translateX(-56px);
	}
}
