@import "variables";@import "mixins";
.questionnaire-input {
  padding: 16px;
  width: 100%;
  background: $color-white;
  border: 1px solid #dadada;
  border-radius: $border-radius-default !important;
  box-sizing: border-box;
  box-shadow: $container-drop-shadow;
  transition: $transition-element;
  -webkit-user-select: text;

  &:focus {
    outline: none;
    border: 1px solid #01c39d;
  }
}