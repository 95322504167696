@import "variables";@import "mixins";
.question-heatmap {
	position: relative;

	&__title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		align-content: center;
		margin: 0 0 8px 0;

		> .uic-typography-root {
			margin: 0;
			display: inline-block;
			font-family: 'Poppins';
			font-size: 12px;
			font-weight: 400;
			line-height: 18px;
			letter-spacing: 0px;
			text-align: left;
			color: #a7a7a7;
		}
	}

	&__svg-button {
		border: none;
		background-color: transparent;
		padding: 0;
	}

	&__reset-button {
		position: relative;
		display: inline-block;
		cursor: pointer;
		width: 18px;
		height: 18px;
		padding: 8px;
		margin-left: auto;

		&.active svg path {
			fill: $color-primary;
		}
	}

	&__expand-button {
		position: relative;
		cursor: pointer;
		width: 18px;
		height: 18px;
		padding: 10px 8px 4px 8px;
	}

	&__cursor-relative-wrapper {
		position: relative;
		margin: 0 auto;

		&.splits {
			width: 282px;

			&.image-only {
				width: 100%;
			}
		}
	}

	&__product-view {
		min-height: 516px; // THIS NEEDS TO BE THE SAME AS DASHBOARD REPORTING HEIGHT CURRENTLY 516px

		&.image-only {
			min-height: auto;
		}
	}

	.question-heatmap__image-container.splits {
		.question-heatmap__image-click-point--comment {
			width: 272px;

			@media only screen and (max-width: 356px) {
				width: 230px;
			}
		}

		.card-view-product.doubleText .stack-card-section-text:first-child {
			border: none;
		}

		.question-heatmap__product-view {
			.card-view-product__container {
				min-height: inherit;
			}

			.card-view-product {
				cursor: context-menu;
				width: 280px !important; // *** THIS NEEDS TO === DASHBOARD .product-card WIDTH
				min-height: inherit;
				margin-bottom: 0px;

				.stack-card-section-text-container:only-of-type {
					min-height: inherit;
				}
			}
		}
	}

	&__image-container {
		position: relative;
		cursor: none;
		touch-action: pan-y;
		border: none;
		padding: 0;
		background-color: transparent;

		&.click-limit {
			cursor: not-allowed;

			.question-heatmap__product-view {
				cursor: not-allowed;
			}
		}

		&.splits {
			width: 282px;
			margin: 0 auto;

			&.image-only {
				width: 100%;
				transform: none;

				@media only screen and (max-width: 356px) {
					transform: none;
				}
			}
		}
	}

	&__image-container-gesture-container {
		overflow: hidden;

		&.splits {
			width: 282px;
			margin: 0 auto;

			&.image-only {
				width: 100%;
				transform: none;

				@media only screen and (max-width: 356px) {
					transform: none;
				}
			}
		}
	}

	&__image-wrapper-button {
		padding: 0;
		border: 0;
		background-color: transparent;
		outline: none; // Will be applied to the parent
		cursor: none;
	}

	&__image {
		min-height: 80px;
		width: 100%;
		max-width: 100%;
		pointer-events: none;
	}

	&__image-click-point {
		position: absolute;
		cursor: pointer;
		transform: translateX(-12px) translateY(-12px);
		width: 24px;
		height: 24px;
		background-color: #28b681;
		outline: 2px solid white;
		border: 0;
		border-radius: 50px;

		// TODO: Testing Point ids, Remove this after
		line-height: 24px;
		text-align: center;
		color: white;

		&.no-comments {
			cursor: default;
		}

		&--comment {
			position: absolute;
			width: 300px;
			min-height: 77px;
			border-radius: 10px;
			border: 1px solid #bababa;
			background: linear-gradient(0deg, #ffffff, #ffffff), linear-gradient(0deg, #bababa, #bababa);
			transform: translateY(calc(-100% + 80px));

			@media only screen and (max-width: 356px) {
				// TODO: Mobile min/max?
				width: 230px;
			}

			@media only screen and (min-width: 357px) {
				width: 300px;
			}

			&-input {
				display: block;
				cursor: text;
				outline: none;
				border: none;
				border-radius: 10px;
				height: auto;
				min-height: 21px;
				width: calc(100% - 16px);
				max-width: calc(100% - 16px);
				max-height: 100px;
				padding: 8px;

				overflow-y: auto;

				&:focus-visible {
					outline: 0;
				}

				// Hide scrollbar for Chrome, Safari and Opera
				&::-webkit-scrollbar {
					display: none;
				}

				overflow: hidden;
				// Hide scrollbar for IE, Edge and Firefox
				-ms-overflow-style: none; // IE and Edge
				scrollbar-width: none; // Firefox
				overflow-y: auto;
			}

			&.view-only {
				svg {
					cursor: pointer;
					width: 18px;
					height: 20px;
					padding: 2px 3px;
				}
			}
		}

		&--comment-divider {
			width: 100%;
			height: 1px;
			background-color: #e8e8e8;
		}

		&--comment-checkmark-container,
		&--comment-delete-container {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding: 5px 8px;
			border: none;
			background-color: transparent;
			margin-left: auto;

			svg {
				cursor: not-allowed;
			}

			&.valid {
				svg {
					cursor: pointer;
				}

				svg rect {
					fill: #005832;
				}
			}
		}
	}

	&__product-view {
		cursor: context-menu;

		&.has-video {
			* {
				pointer-events: none;
			}
		}
	}

	.image-zoom-product-cards__preview-container {
		pointer-events: none;
	}

	&__img-only {
		width: 100%;
		pointer-events: none;
	}

	.question-heatmap__image-container.splits.expanded {
		cursor: default;

		position: fixed;
		top: 0px;
		transform: translateX(-50%);
		left: 50%;
		width: 100%;
		height: 100%;
		max-height: 100%;
		z-index: 999;

		.image-zoom-product-cards__preview-container {
			pointer-events: all;
		}

		.card-view-product__container {
			margin: 0 auto;
		}

		.question-heatmap__product-view {
			height: 100%;
			max-height: 100%;
			cursor: default;

			.card-view-product {
				// height: 100vh !important;
				// max-height: 100vh !important;
				cursor: default;
			}
		}

		.question-heatmap__image-click-point {
			opacity: 0;
			pointer-events: none;
		}
	}

	.commitment-view-product {
		display: none;
	}

	&__image-click-point--comment-cancel {
		all: unset;
		font-size: 10px;
		opacity: 0.5;
		margin-right: auto;
		cursor: pointer;
	}

	[class^='loader'] {
		position: absolute;
		width: 40px;
		height: 40px;
		top: calc(50% - 20px);
		left: calc(50% - 20px);
	}

	.product-card-accessibility-wrapper {
		min-height: 516px;

		&.show-acessibility-bug-view {
			min-height: unset;
		}
	}
}
