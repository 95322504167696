@import "variables";@import "mixins";
$footerHeight: 80px; // 68px footer height + 12px padding
$devicePreviewBannerHeight: 40px;

.scroll-container.scrolled-to-bottom .monadic-split-container__question-container .card-view-product,
.remove-scroll-indicator .monadic-split-container__question-container .card-view-product {
	// Don't remove inner scroll indicator within idea card above question
	.global-scroll-indicator {
		display: flex;
	}
}

.device-preview-header + .scroll-container {
	.monadic-split-container {
		margin-top: -$devicePreviewBannerHeight;
	}
}

.monadic-split-container {
	display: flex;
	flex-direction: column;

	&__product-container {
		height: 100%;
	}

	&__product-container,
	&__statement-container,
	&__question-container {
		margin-top: auto;
		margin-bottom: auto;
	}

	.monadic-split-container__question-container {
		.card-view-product {
			height: 30vh;
			z-index: 0;
			border: 1px solid #dadada;
			border-radius: 4px;
			box-shadow: none !important;

			.global-scroll-indicator {
				position: absolute;
				bottom: 10px;
				z-index: 900;
			}
		}
	}

	&__progress-container-container {
		margin-bottom: 8px;
	}

	.question .progress-bar__container {
		margin-bottom: 0px;
	}

	&__content {
		padding: 0px 20px 20px 20px;
	}

	// * Matching the width of the product card
	&__questions-container {
		width: 90%;
		margin: 0 auto;
		margin-top: 4px;
	}

	&__number-of-ideas-container {
		text-align: center;
		margin-top: 20px;
	}

	&__inline-button-container {
		margin: 0px;
		padding: 0px;

		.monadic-split-container__button {
			display: flex;
			justify-content: center;
			display: -webkit-flex;
			-webkit-justify-content: center;
		}

		.basic-button {
			margin: 20px 0px;
		}
	}

	&__product-card-indicator-container {
		width: 90%;
		margin: 0 auto;

		&.add-margin-bottom {
			margin-bottom: 20px;
		}
	}
	&__product-card-indicator-container-video-question {
		width: 90%;
		max-width: 1212px;
		margin: 0 auto;

		&.add-margin-bottom {
			margin-bottom: 20px;
		}
	}

	.card-view-product {
		margin: 0 auto;
		margin-bottom: 16px;
		cursor: auto;
		height: calc(100% - 36px);
		box-shadow: none;
		border: 1px solid #dadada;
		border-radius: 4px;
	}

	.commitment-view-product {
		height: 30vh;
		z-index: 0;
		border: 1px solid #dadada;
		border-radius: 4px;
		box-shadow: none !important; //overriding inline product cards shadows
	}

	.commitment-view-product .image-zoom-product-cards {
		pointer-events: none;
	}

	.commitment-view-product__stack-product.open {
		.image-zoom-product-cards {
			pointer-events: all;
		}
	}

	.commitment-view-checkbox {
		display: none;
	}

	.tradeoff-view-product {
		border: 1px solid $color-grey-E8;
	}

	.question .question {
		width: 100%;
	}

	// .basic-button {
	// 	width: 90%;
	// 	margin: 0 auto;
	// }

	// TODO: Figure out why flex: 1 1 33%; is on .new-card-section-text in the product-cards repo, and if its not needed remove it
	// ? Also why are product cards on the survey divided in thirds visually on the survey but not on the dashboard?
	// * Makes sense on the image size because of image_size setting, but not sure why the setting is on the text
	.new-card-section-text {
		flex: inherit !important;
	}

	.card-view-product.expanded {
		top: 0px;
		height: 100vh;
		margin: 0;
		padding: 0;
	}

	.monadic-split-product-image-button-container {
		position: absolute;
		bottom: 0px;
		max-width: 600px;
		margin: 0;
		width: 100%;
		display: flex;
		justify-content: flex-end;
		display: -webkit-flex;
		-webkit-justify-content: flex-end;

		.basic-button {
			margin: 20px auto;
			width: 90%;
		}
	}

	// Add responsiveness for when clicking a commitment view product in a monadic split question
	@media only screen and (min-width: 1000px) {
		.commitment-view-product__stack-product {
			width: 60%;
			left: calc(50% - 30%);
		}
	}

	@media only screen and (min-width: 1400px) {
		.commitment-view-product__stack-product {
			width: 40%;
			left: calc(50% - 20%);
		}
	}

	.ql-size-small {
		font-size: 11px;
	}

	.ql-size-large {
		font-size: 24px;
		line-height: 36px;
	}

	.ql-direction-rtl {
		direction: rtl;
		text-align: inherit;
	}

	.ql-align-center {
		text-align: center;
	}

	.ql-align-right {
		text-align: right;
	}

	.ql-align-justify {
		text-align: justify;
	}

	.ql-indent-1 {
		padding-left: 3rem !important;
	}

	.ql-indent-2 {
		padding-left: 6rem !important;
	}

	.ql-indent-3 {
		padding-left: 9rem !important;
	}

	.ql-indent-4 {
		padding-left: 12rem !important;
	}

	.ql-indent-5 {
		padding-left: 15rem !important;
	}

	.ql-indent-6 {
		padding-left: 18rem !important;
	}

	.ql-indent-7 {
		padding-left: 21rem !important;
	}

	.ql-indent-8 {
		padding-left: 24rem !important;
	}

	ol {
		padding-left: 0px;
	}

	ol,
	ul {
		list-style-type: none;

		li[data-list='ordered'] {
			counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
			counter-increment: list-0;

			&:before {
				content: counter(list-0, decimal) '. ';
			}

			&::marker {
				display: none;
				content: none;
			}

			&.ql-indent-1 {
				counter-increment: list-1;
				margin-left: 3rem !important;
				padding-left: 0rem !important;
				counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8;
			}

			&.ql-indent-2 {
				counter-increment: list-2;
				margin-left: 6rem !important;
				padding-left: 0rem !important;
				counter-reset: list-1 list-3 list-4 list-5 list-6 list-7 list-8;
			}

			&.ql-indent-3 {
				counter-increment: list-3;
				margin-left: 9rem !important;
				padding-left: 0rem !important;
				counter-reset: list-1 list-2 list-4 list-5 list-6 list-7 list-8;
			}

			&.ql-indent-4 {
				counter-increment: list-4;
				margin-left: 12rem !important;
				padding-left: 0rem !important;
				counter-reset: list-1 list-2 list-3 list-5 list-6 list-7 list-8;
			}

			&.ql-indent-5 {
				counter-increment: list-5;
				margin-left: 15rem !important;
				padding-left: 0rem !important;
				counter-reset: list-1 list-2 list-3 list-4 list-6 list-7 list-8;
			}

			&.ql-indent-6 {
				counter-increment: list-6;
				margin-left: 18rem !important;
				padding-left: 0rem !important;
				counter-reset: list-1 list-2 list-3 list-4 list-6 list-7 list-8;
			}

			&.ql-indent-7 {
				counter-increment: list-7;
				margin-left: 21rem !important;
				padding-left: 0rem !important;
				counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-8;
			}

			&.ql-indent-8 {
				counter-increment: list-8;
				margin-left: 24rem !important;
				padding-left: 0rem !important;
				counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7;
			}
		}
	}

	ol li[data-list='bullet'] {
		&.ql-indent-1::before,
		&.ql-indent-2::before,
		&.ql-indent-3::before,
		&.ql-indent-4::before,
		&.ql-indent-5::before,
		&.ql-indent-6::before,
		&.ql-indent-7::before,
		&.ql-indent-8::before,
		&:before {
			content: counter(list-0, disc) ' ' !important;
		}
	}

	ol li[data-list='ordered'] {
		&.ql-indent-1::before {
			content: counter(list-1, lower-alpha) '. ' !important;
		}

		&.ql-indent-2::before {
			content: counter(list-2, lower-roman) '. ' !important;
		}

		&.ql-indent-3::before {
			content: counter(list-3, decimal) '. ' !important;
		}

		&.ql-indent-4::before {
			content: counter(list-4, lower-alpha) '. ' !important;
		}

		&.ql-indent-5::before {
			content: counter(list-5, lower-roman) '. ' !important;
		}

		&.ql-indent-6::before {
			content: counter(list-6, decimal) '. ' !important;
		}

		&.ql-indent-7::before {
			content: counter(list-7, lower-alpha) '. ' !important;
		}

		&.ql-indent-8::before {
			content: counter(list-8, lower-roman) '. ' !important;
		}
	}

	.card-view-product.titleBottom {
		& > .stack-card-section-images + .stack-card-section-text {
			padding-top: 0px;
			padding-bottom: 0px;
		}

		& > .stack-card-section-images ~ .stack-card-section-text {
			padding-top: 0px;
		}
	}

	.question,
	.grid-question {
		&__question-label-image-container {
			position: relative;
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.image-zoom__preview-container {
				img {
					max-height: 150px;
				}
			}

			.question__question-image {
				padding-left: 8px;
			}
		}

		.question-label {
			flex: 1 1 auto;

			& + * {
				min-width: 40%;
			}
		}

		&__question-image {
			display: flex;
			flex-direction: column;
			align-self: center;
			min-width: 50x;
			max-height: 50x;
			max-width: 50x;
			padding: 0;
			margin-top: 8px;
		}

		&__sideBySide .grid-question__attribute-image,
		&__attribute-image {
			max-height: 50px;
		}

		&__attribute-label {
			padding: 4px 12px;
		}

		.question-multi-select__option-image-container,
		.question-single-select__option-image-container {
			max-height: 50px;
		}
	}

	&__loader-container {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.commitment-view-product.imageOnly .image-zoom-product-cards,
	.card-view-product.imageOnly .image-zoom-product-cards {
		pointer-events: unset;
	}

	.card-view-product.doubleText .stack-card-section-text-container:first-child .stack-card-section-text {
		justify-content: center;
	}

	.card-view-product.textOnly .stack-card-section-text-container {
		display: flex;
		justify-content: center;
		height: 100%;
	}

	.stack-card-section-text p {
		font-size: 13px;
	}
}
