@import "variables";@import "mixins";
$optionPaddingY: 12px;
$optionPaddingX: 12px;

.ranked-question-container {
	touch-action: manipulation;
	margin: 28px -4px -4px; // Compensate padding for keyboard navigation outline
	width: 50%;
	max-height: 560px;
	overflow-y: auto;
	overflow-x: hidden;

	&__title {
		font-size: 16px;
		font-weight: 600;
		margin-bottom: 12px;
		padding: 0 4px;
		display: flex;
		justify-content: space-between;
	}

	&__rank-count {
		font-size: 10px;
		font-weight: 400;
		color: $color-black;
	}

	&__no-items {
		min-height: 56px;
		border: 1px dashed $color-grey-darker;
		border-radius: 4px;
		background-color: $color-grey-FA;
		display: flex;
		align-items: center;

		p {
			margin: 0;
			width: 100%;
			text-align: center;
		}
	}

	&__desktop {
		position: relative;
		height: 70vh;
	}

	&__idea-splits {
		height: 45vh;
	}

	&__title-desktop {
		position: sticky;
		z-index: 5;
		top: 0;
		background: $color-white;
		margin-bottom: 0;
		padding-bottom: 12px;
	}

	&__options {
		padding: 4px; // For keyboard navigation outline
	}

	@media (max-width: $screen-md-min) {
		width: 100%;
		max-height: fit-content;
		overflow: visible;
	}
}
