@import "variables";@import "mixins";
.video-player {
	display: flex;
	margin-bottom: 8px;
	background-color: black;

	* {
		width: 100% !important;
		height: 360px  !important;
	}
}
