@import "variables";@import "mixins";
$footerHeight: 80px; // 68px footer height + 12px padding
$devicePreviewBannerHeight: 40px;

.scroll-container.scrolled-to-bottom .commitment-card-stack .commitment-card-item,
.remove-scroll-indicator .commitment-card-stack .commitment-card-item {
	// Don't remove inner scroll indicator within idea card above question
	.global-scroll-indicator {
		display: flex;
	}
}

@keyframes move-up {
	0% {
		transform: translateY(calc(50vh - 24px));
	}

	50% {
		transform: translateY(calc(50vh - 24px));
	}

	100% {
		transform: translateY(0);
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes expand-checkbox {
	0% {
		transform: scale(1) translate(20%, 20%);
		box-shadow: 0 0 0 0 rgba(180, 180, 180, 0.7);
		border-radius: 50px;
	}

	50% {
		transform: scale(1.25) translate(0%, 0%);
		box-shadow: 0 0 0 10px rgba(180, 180, 180, 0);
	}

	100% {
		transform: scale(1) translate(20%, 20%);
		box-shadow: 0 0 0 10px rgba(180, 180, 180, 0);
	}
}

.commitment-card-stack {
	@include flex-box;
	@include flex-wrap(nowrap);
	@include flex-direction(column);
	@include justify-content(space-between);
	@include align-items(stretch);
	@include align-content(stretch);
	transition: all ease-in-out 500ms;
	opacity: 0;

	height: 100%;
	height: calc(100vh - 50px);

	margin-bottom: 30px;
	z-index: 100;

	h2 {
		flex: 1;
		@include flex-grow(1);
		@include align-self(auto);
		@include align-items(center);
		color: $color-black;
		margin-bottom: 15px;
		text-align: center;
	}

	&__separator {
		@include flex-box;
		@include justify-content(space-between);
		@include align-items(center);
		font-size: 14px;
		margin: 6px 0;
		width: 100%;

		span {
			flex: 0 0 auto;
			width: 50px;
		}

		&:before,
		&:after {
			content: '';
			height: 1px;
			flex: 1 1 auto;
			background: $color-black;
		}
	}

	&__checkbox {
		position: absolute;
		right: 5%; // card view 90% width, 5% on each side
		bottom: 0;
		z-index: 999;
	}

	.image-zoom-product-cards {
		pointer-events: none;
	}

	.commitment-view-product.imageOnly .image-zoom-product-cards {
		pointer-events: unset;
	}

	.commitment-view-product__stack-product.open {
		.image-zoom-product-cards {
			pointer-events: all;
		}
	}
}

.commitment-card-separator {
	margin: 10px auto;
	text-align: center;
	width: 90%;
	color: $color-black;
}

.commitment-card-item {
	position: relative;
	@include flex-box;
	@include flex-wrap(nowrap);
	@include flex-direction(column);
	@include justify-content(center);
	@include align-items(stretch);
	@include align-content(space-between);

	flex: 1;
	@include flex-grow(1);
	@include align-self(auto);
	@include align-items(center);

	cursor: default;
	min-height: 30vh;

	img {
		width: 100%;
	}

	*:focus {
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	*::selection {
		background: transparent;
	}

	*::-moz-selection {
		background: transparent;
	}

	.card-view-product:not(.doubleText) {
		.global-scroll-indicator {
			bottom: calc(50% + 30px);
		}
	}

	.card-view-product__section.sideBySide {
		.global-scroll-indicator {
			bottom: 10px;
		}
	}

	& + .commitment-card-separator + .commitment-card-item {
		.card-view-product:not(.doubleText) {
			.global-scroll-indicator {
				bottom: 50px; // 10px top padding + 30px bottom margin + 10px spacing to keep consistent with other sections
			}
		}

		.card-view-product__section.sideBySide {
			.global-scroll-indicator {
				bottom: 10px;
			}
		}
	}

	.card-view-product.expanded {
		.global-scroll-indicator {
			bottom: 10px;
		}
	}
}

.commitment-card-stack-wrapper.animate {
	.commitment-card-stack {
		.commitment-card-text-container {
			animation: move-up 2s 1;

			& > h2 {
				&:focus,
				&:focus-visible {
					outline: none;
				}
			}
		}

		.commitment-card-item,
		.commitment-card-separator {
			opacity: 1;
			animation: fade-in 3s 1;
		}

		.commitment-card-item {
			.commitment-view-checkbox {
				animation: expand-checkbox 0.75s 2;
				animation-delay: 2s;
			}
		}
	}
}
