@import "variables";@import "mixins";
@keyframes jumpInfinite {

    0% {
        transform: translateY(0px);
        opacity: 100%;
    }

    10% {
        transform: translateY(-5px) scaleY(0.9);
        opacity: 100%;
    }

    30% {
        transform: translateY(15px) scaleY(0.8);
        opacity: 0%;
    }

    50% {
        transform: translateY(0px);
        opacity: 100%;
    }

    100% {
        transform: translateY(0px);
        opacity: 100%;
    }
}

.global-scroll-indicator {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    bottom: 10px;
    left: 0;
    width: 100%;
    z-index: 900;

    &-icon-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        pointer-events: all;
        /* fallback */
        background: #28B681;
        // /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        // background: linear-gradient(to top, hsla(158, 64%, 44%, 0.5) 20%, #008855 100%);
        // /* Chrome 10-25, Safari 5.1-6 */
        // background: -webkit-linear-gradient(to top, #28B681 0%, #1f4037 100%);

        width: 40px;
        height: 40px;
    }

    svg {
        animation: jumpInfinite 3s infinite;

        * {
            fill: white;
        }
    }
}

.scroll-container.scrolled-to-bottom {
    .global-scroll-indicator {
        display: none;
    }
}

.global-scroll-indicator.background-blur {
    backdrop-filter: blur(2px);
}

.remove-scroll-indicator {
    .global-scroll-indicator {
        display: none;
    }
}