@import "variables";@import "mixins";
.maintenance-container {
	position: absolute;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.maintenance-component {
	display: flex;
	width: 100%;
	height: 100%;
	flex-flow: column;
	background: white;
	justify-content: space-between;
	line-height: normal;
	&__content {
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;
		height: 100%;
	}
	&__image {
		margin: 24px 0;
		max-height: 100px;
	}
	&__title,
	&__subtitle {
		margin: 0 6px;
	}
	&__title {
		font-size: 25px;
		font-weight: 600;
		color: $color-black;
		margin-bottom: 16px;
		text-align: center;
		// line-height: normal;
	}
	&__subtitle {
		font-size: 14px;
		color: $color-black;
		margin-bottom: 24px;
		max-width: 520px;
		text-align: center;
		// line-height: normal;
	}
	a,
	button {
		border-radius: 4px;
		padding: 8px 16px;
		text-decoration: none;
		cursor: pointer;
	}
	&__footer {
		display: flex;
		justify-content: center;
		width: 100%;
		padding-bottom: 50px;
		a.maintenance-component__status-button {
			background-color: #28b681;
			border-radius: 4px;
			font-size: 12px;
			font-weight: bold;
			letter-spacing: 0.2px;
			// line-height: normal;
			// Text colour
			color: white;
			&:hover {
				color: white;
			}
		}
	}
}
