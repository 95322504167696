@import "variables";@import "mixins";
.scroll-container.android-mobile,
.scroll-container.ios-mobile {
	.card-view__button.with-pulse {
		--scaleUp: 1.15;
		animation: pulse 1.5s 2;
	}
}

@keyframes pulse {
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(180, 180, 180, 0.7);
	}

	70% {
		transform: scale(var(--scaleUp));
		box-shadow: 0 0 0 10px rgba(180, 180, 180, 0);
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(180, 180, 180, 0);
	}
}

@keyframes load-in-accept {
	0% {
		top: 60%;
		left: 50%;
		right: auto;
		bottom: auto;
		width: 0;
		height: 0;
		background-color: #ffffff00;
	}

	90% {
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		background-color: #ffffff;
	}

	100% {
		opacity: 0;
	}
}

@keyframes load-in-reject {
	0% {
		top: 60%;
		left: 50%;
		right: auto;
		bottom: auto;
		width: 0;
		height: 0;
		background-color: #ffffff00;
	}

	90% {
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		background-color: #ffffff;
	}

	100% {
		opacity: 0;
	}
}

.card-view {
	align-items: stretch;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	position: relative;

	&__controls {
		width: 100%;
		position: absolute;
		z-index: 200;
		bottom: 20px;
		left: 0;
		right: 0;
		max-width: $max-stage-width;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		.body.rtl & {
			direction: ltr;
		}
	}

	&__button-bot-detection-loader {
		position: absolute;
		top: 0px;
		right: 0px;
		width: 100%;
		height: 100%;
		transition: opacity 100ms ease-in-out;
		opacity: 0.85;
		border-radius: 4px;

		&.hidden {
			opacity: 0;
		}

		&.reject {
			background-color: #ffe6e3;
		}

		&.accept {
			background-color: #d1ffe6;
		}

		&.load-in {
			transition: width 500ms ease-in-out;
			width: 0px;

			&.accept {
				animation: load-in-accept;
				animation-delay: 500ms;
				animation-duration: 500ms;

				-webkit-animation: load-in-accept;
				-webkit-animation-delay: 500ms;
				-webkit-animation-duration: 500ms;
			}

			&.reject {
				animation: load-in-reject;
				animation-delay: 500ms;
				animation-duration: 500ms;

				-webkit-animation: load-in-reject;
				-webkit-animation-delay: 500ms;
				-webkit-animation-duration: 500ms;
			}
		}
	}

	&__button {
		position: relative;
		@include transition(all, 0.1s, ease-out);
		@include multishadow(0, 5px, 16px, 0, rgba($color-black, 0.12), 0, 17px, 50px, 0, rgba($color-black, 0.1));

		background: $color-like;
		border-radius: 4px;
		color: $color-white;
		cursor: pointer;
		display: inline-block;
		height: 45px;
		min-height: 45px;
		line-height: 45px;
		vertical-align: middle;
		width: calc(45% - 15px);
		text-align: center;
		min-width: 100px;
		border: none;

		&.accept {
			background-color: $color-green-600;
			margin-left: 15px;

			@include md {
				margin-left: 25px;
			}

			&:focus,
			&:hover {
				background-color: darken($color-green-600, 3%);
			}

			&:active {
				background-color: darken($color-green-600, 5%);
			}
		}

		&.reject {
			background-color: $color-dislike;
			margin-right: 15px;

			@include md {
				margin-right: 25px;
			}

			&:focus,
			&:hover {
				background-color: darken($color-dislike, 3%);
			}

			&:active {
				background-color: darken($color-dislike, 5%);
			}
		}

		&.with-pulse {
			--scaleUp: 1.25;
			animation: pulse 1.5s 2;

			@include md {
				--scaleUp: 1.2;
				animation: pulse 1.5s 2;
			}
		}

		&.scale-up {
			transform: scale(1.15);
		}

		img {
			vertical-align: middle;
		}

		&:active {
			@include multishadow(0, 2px, 16px, 0, rgba($color-black, 0.12), 0, 5px, 60px, 0, rgba($color-black, 0.1));

			-webkit-transform: scale(0.89);
			-moz-transform: scale(0.89);
			-ms-transform: scale(0.89);
			-o-transform: scale(0.89);
			transform: scale(0.89);
		}

		&:focus {
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}

		&::selection {
			background: transparent;
		}

		&::-moz-selection {
			background: transparent;
		}
	}
}
