@import "variables";@import "mixins";
.permission-checker {
	display: flex;
	align-items: center;
	width: 100%;
	height: 100vh;
	margin: auto;
	flex-direction: row;
	&__upsiide-url-bar {
		position: relative;
		left: -10px;
	}
	&__container {
		display: flex;
		flex-direction: column;
		width: 100%;
		position: relative;
	}
	&__ai-question-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
	}
	&__permission-row {
		display: flex;
		flex-direction: column;
		margin-left: 12px;
	}
	&__permission-text {
		display: inline;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 21px;
		color: $color-black;
	}
	&__permission-subtext {
		display: inline;
		font-size: 10px;
		font-style: normal;
		font-weight: 400;
		line-height: 15px;
		color: $color-black;
	}

	&__ai-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 12px;
	}

	&__ai-content {
		display: flex;
		padding: 16px;
		border-radius: 8px;
		border: 1px solid $color-grey-E8;
		background: $color-white;
		width: 426px;
		align-items: center;
		&:hover {
			background-color: #fafafa;
		}
		& > button {
			margin-left: auto;
		}
	}
	&__ai-content-full {
		display: flex;
		padding: 16px;
		border-radius: 8px;
		border: 1px solid $color-grey-E8;
		background: $color-white;
		width: 426px;
		align-items: center;
		&:hover {
			background-color: #fafafa;
		}
		& > button {
			margin-left: auto;
		}
	}
	&__ai-permission {
		display: flex;
		flex-direction: row;
		gap: 8px;
		align-items: center;
		justify-content: center;
	}

	&__button-container {
		margin-left: auto;
	}
	&__ai-footer {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		gap: 16px;
		margin-top: 32px;
		padding-bottom: 24px;
	}
	&__header {
		display: flex;
		margin-bottom: 8px;
		justify-content: space-between;
		> img {
			width: 12px;
			height: 17px;
			cursor: pointer;
		}
	}
	&__ai-header {
		display: flex;
		flex-direction: column;
		margin-bottom: 32px;
	}

	&__ai-title {
		text-align: center;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 30px;
		color: $color-black;
	}

	&__sub-title {
		color: $color-grey-6c;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 21px;
		margin-top: 8px;
		text-align: center;
	}

	&__button {
		padding: 8px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		border-radius: 6px;
		max-width: 463px;
		&:hover {
			background-color: $keyline-button-hover-background;
		}
	}

	&__button-allow {
		width: 131px;
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		background-color: $color-green-600;
		border-radius: 6px;
		color: $color-white;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 21px;
		&:hover {
			background-color: #5bc397;
		}
	}
	&__button-primary {
		width: 444px;
		padding: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		background-color: $color-green-600;
		border-radius: 8px;
		color: $color-white;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 21px;
		margin-right: 16px;
		margin-left: 16px;
		&:hover {
			background-color: #5bc397;
		}
	}
	&__button-disabled {
		width: 444px;
		padding: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: default;
		background-color: $color-grey-superlight;
		border-radius: 8px;
		color: $color-grey-darker;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 21px;
		margin-right: 16px;
		margin-left: 16px;
		&:hover {
			background-color: $color-grey-superlight;
		}
	}
	&__button-secondary {
		width: 460px;
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		border-radius: 8px;
		background: $color-white;
		color: $color-grey-6c;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 21px;
		margin-top: 16px;
		margin-right: 16px;
		margin-left: 16px;
		&:hover {
			background-color: $color-grey-transparent-bg;
		}
	}

	&__placeholder {
		width: 100%;
		position: relative;
	}
	&__old-lady-placeholder {
		width: 100%;
	}

	&__video-context {
		opacity: 0;
		width: 1px;
		height: 1px;
	}
	&__canvas-video {
		width: 100%;
		height: 100%;
		-webkit-transition: opacity 1s ease-in-out;
		-moz-transition: opacity 1s ease-in-out;
		-o-transition: opacity 1s ease-in-out;
		transition: opacity 1s ease-in-out;
		position: absolute;
	}

	&__button-blocked {
		margin-left: auto;
		border-radius: 3px;
		background: $color-red-superlight;
		display: flex;
		height: 20px;
		padding: 2px 8px;
		justify-content: center;
		align-items: center;
		gap: 4px;
	}
	&__button-blocked-mobile {
		border-radius: 3px;
		background: $color-red-superlight;
		display: flex;
		height: 16px;
		padding: 2px 8px;
		width: 40px;
		justify-content: center;
		align-items: center;
		gap: 4px;
		margin-top: 2px;
	}
	&__text-blocked {
		color: #764040;
		font-size: 10px;
		font-style: normal;
		font-weight: 400;
		line-height: 15px;
	}
	&__button-allowed {
		margin-left: auto;
		border-radius: 3px;
		background: $color-green-very-light;
		display: flex;
		height: 20px;
		padding: 2px 8px;
		justify-content: center;
		align-items: center;
		gap: 4px;
	}
	&__text-allowed {
		color: #005832;
		font-size: 10px;
		font-style: normal;
		font-weight: 400;
		line-height: 15px;
	}

	&__ai-blocked {
		display: flex;
		margin-bottom: 24px;
		align-items: center;
	}
	&__ai-content-blocked {
		display: flex;
		padding: 16px;
		border-radius: 8px;
		border: 1px solid $color-grey-E8;
		background: $color-white;
		width: 426px;
		flex-direction: column;
		&:hover {
			background-color: #fafafa;
		}
	}
	&__ai-instructions {
		display: inline;
		align-items: center;
		> span {
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 18px;
			> span,
			button {
				padding: 0;
				border: none;
				background: none;
				display: inline-flex;
				align-items: center;
				cursor: pointer;
				font-weight: 600;
				color: $color-green-600;
			}
		}
	}
	&__ai-instructions-mobile {
		display: inline;
		align-items: center;
		margin-top: 8px;
		> span {
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 18px;
			> span {
				display: inline-flex;
				cursor: pointer;
				font-weight: 600;
				color: $color-green-600;
			}
		}
	}
	&__img-setting {
		margin-right: 2px;
		position: relative;
		top: 2px;
	}

	&__frequency-bar {
		display: flex;
		width: 1.5%;
		min-height: 17%;
		background-color: #5bc397;
		border-radius: 2px;
	}
	&__audio-bars {
		position: absolute;
		width: 33%;
		height: 8%;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		gap: 1.5%;
		z-index: 9;
		margin-top: 518px;
	}
	&__preview-camera {
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		background: $color-grey-transparent-bg;
		border-radius: 12px;
	}
	&__active-border {
		border: 3px solid #5bc397;
		border-radius: 12px;
		position: absolute;
		top: -2px;
		left: 0px;
		width: 99%;
		height: 600px;
		z-index: 2;
	}
	&__left-side-container {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: #fafafa;
	}
	&__square-wrapper {
		flex: 1;
		aspect-ratio: 1 / 1;
		overflow: hidden;
		border-radius: 12px;
		min-width: 300px;
		max-width: 600px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;

		@media (max-width: 490px) {
			max-width: 300px;
		}

		&:last-of-type {
			@media (max-width: 490px) {
				aspect-ratio: auto;
			}
		}
	}

	@media (max-width: 1270px) {
		&__container {
			margin-right: 90px;
		}
		&__placeholder {
			display: flex;
			justify-content: flex-end;
			margin-right: 80px;
			> img {
				width: 400px !important;
			}
		}
	}
	@media (max-width: 1120px) {
		&__container {
			margin-right: 45px;
		}
	}
	@media (max-width: 1060px) {
		&__container {
			margin-left: 20px;
			margin-right: 20px;
		}
	}
	@media (max-width: 975px) {
		&__placeholder {
			margin-right: 200px;
			> img {
				width: 300px !important;
			}
		}
	}

	@media (max-width: 850px) {
		max-width: 100%;
		flex-direction: column-reverse;
		justify-content: flex-end;
		&__permission-checker {
			max-width: 100%;
			flex-direction: column-reverse;
		}
		&__container {
			width: 305px;
			max-width: 90%;
			margin-right: 0px;
			margin-left: 0px;
		}

		&__ai-question-container {
			width: 305px;
			max-width: 90%;
			margin-top: 32px;
			margin-right: 0px;
			margin-left: 0px;
		}
		&__ai-content {
			width: 330px;
			max-width: 80vw;
			&:hover {
				background-color: #fff;
			}
		}
		&__ai-content-full {
			width: 330px;
			max-width: 80vw;
			&:hover {
				background-color: #fff;
			}
		}
		&__ai-content-blocked {
			width: 330px;
			max-width: 80vw;
			&:hover {
				background-color: #fff;
			}
		}

		&__button-allow {
			&:hover,
			&:focus {
				background-color: darken($color-green-600, 3%);
			}
		}

		&__left-side-container {
			margin-top: 12px;
			height: unset;
		}
		&__placeholder {
			display: flex;
			justify-content: flex-start;
			margin-right: 0px;
			> img {
				width: 100% !important;
			}
		}

		&__ai-footer {
			width: 80vw;
			max-width: 360px;
		}
		&__ai-blocked {
			margin-bottom: 12px;
			max-width: 80vw;
		}
		&__active-border {
			width: 98%;
			height: 97%;
			top: 1px;
		}
		&__header {
			margin-bottom: 12px;
			max-width: 320px;
		}
		&__button-primary {
			max-width: 360px;
		}
		&__button-disabled {
			max-width: 360px;
		}
		&__button-secondary {
			max-width: 360px;
			margin-bottom: 24px;
		}
		&__content {
			width: 295px;
		}
		&__no-camera-container {
			height: 300px;
		}
		&__no-access {
			padding: 10px;
			gap: 8px;
		}
		&__audio-bars {
			margin-top: 250px;
		}
	}

	@media (max-width: 490px) {
		max-width: 100%;
		flex-direction: column-reverse;
		justify-content: flex-end;
		&__permission-checker {
			max-width: 100%;
			flex-direction: column-reverse;
		}
		&__container {
			width: 305px;
			max-width: 90%;
			margin-right: 0px;
			margin-left: 0px;
		}

		&__ai-question-container {
			width: 305px;
			max-width: 90%;
			margin-top: 32px;
			margin-right: 0px;
			margin-left: 0px;
		}
		&__ai-content {
			width: 330px;
			max-width: 80vw;
			&:hover {
				background-color: #fff;
			}
		}
		&__ai-content-full {
			width: 330px;
			max-width: 80vw;
			&:hover {
				background-color: #fff;
			}
		}
		&__ai-content-blocked {
			width: 330px;
			max-width: 80vw;
			&:hover {
				background-color: #fff;
			}
		}

		&__button-allow {
			&:hover,
			&:focus {
				background-color: darken($color-green-600, 3%);
			}
		}

		&__left-side-container {
			height: 300px !important;
		}
		&__ai-footer {
			width: 80vw;
		}
		&__ai-blocked {
			margin-bottom: 12px;
			max-width: 80vw;
		}
		&__active-border {
			width: 98%;
			height: 97%;
			top: 1px;
		}
		&__header {
			margin-bottom: 12px;
			max-width: 320px;
		}
		&__ai-footer {
			max-width: 85vw;
		}
		&__button-primary {
			max-width: 85vw;
		}
		&__button-disabled {
			max-width: 85vw;
		}
		&__button-secondary {
			max-width: 85vw;
			margin-bottom: 24px;
		}
		&__content {
			width: 295px;
		}
		&__no-camera-container {
			height: 300px;
		}
		&__no-access {
			padding: 10px;
			gap: 8px;
		}
		&__audio-bars {
			margin-top: 250px;
		}
	}

	@media (max-width: 295px) {
		&__permission-text {
			font-size: 11px;
			line-height: 16px;
		}
		&__ai-content {
			padding: 12px;
			> img {
				width: 36px;
			}
		}
		&__permission-row {
			margin-left: 8px;
		}
		&__ai-content-full {
			padding: 12px;
		}
		&__button-allow {
			font-size: 11px;
			width: 85px;
		}
		&__content {
			width: 260px;
		}
		&__ai-header {
			margin-bottom: 24px;
		}
		&__ai-question-container {
			margin-top: 42px;
		}
		&__button-secondary {
			margin-bottom: 4px;
		}
		&__preview-camera {
			width: 278px !important;
			height: 278px !important;
		}
		&__ai-blocked {
			margin-bottom: 0px;
			max-width: 80vw;
		}
		&__button-blocked-mobile {
			margin-left: 2px;
		}
	}
}
