@import "variables";@import "mixins";
.main__loader {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 200px;
}

.question-label {
  font-size: $font-size-header;
  line-height: $font-size-header-line-height;

  p {
    font-size: inherit;
  }
}


body {
  overscroll-behavior: none;
  overflow: hidden;

  * {
    overscroll-behavior-block: none;
    overscroll-behavior-inline: none;
  }

  &.pull-to-refresh {
    overscroll-behavior: auto;
    overflow: auto;

    * {
      overscroll-behavior-block: auto;
      overscroll-behavior-inline: auto;
    }
  }

}