@import "variables";@import "mixins";
@import 'src/styles/_variables.scss';

.feedback-select {
	&__header {
		font-size: 12px;
		display: flex;
		justify-content: space-between;
		padding: 10px 10px 15px 10px;
		border-bottom: 1px $color-grey-light solid;
		margin: 0 -5px;
	}
	&__header-text {
		font-weight: bold;
		padding: 15px auto;
	}
	&__header-remove,
	&__header-close {
		color: $color-grey;
		font-size: 10px;
		&:hover {
			cursor: pointer;
		}
	}
	&__header-remove {
		margin-left: 20px;
		text-decoration: underline;
	}
	&__header-close {
		margin-left: 5px;
	}
	&__options {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		margin: 5px auto;
		text-align: center;
		.option {
			opacity: 0.4;
			font-size: 20px;
		}

		.submit {
			background: #4bdf93;
			color: white;
			line-height: 23px;
			&.active {
				opacity: 1 !important;
			}
		}

		& > * {
			width: 20px;
			margin: 7px;
			opacity: 0.4;
			width: 25px;
			height: 25px;
			line-height: 28px;
			border-radius: 50%;
			padding: 4px;
			&:hover {
				cursor: pointer;
			}
		}
		.selected {
			padding: 4px;
			background-color: $color-primary;
			opacity: 1 !important;
		}
	}
}
