@import "variables";@import "mixins";
.questions-container {
	// overflow: auto;
	// height: calc(100vh - 80px);
	text-align: left;

	hyphens: auto;

	&__heading {
		box-shadow: $section-drop-shadow;
	}

	&__questions {
		padding: 0px 20px 20px 20px;
	}

	&__button {
		padding: 20px;
	}
}