@import "variables";@import "mixins";
$sideBySideImageSize: 40px;

.ranked-question {
	display: flex;
	gap: 20px;

  &__ranked-container {
    min-height: 200px !important;
  }

	// ReversedRadioButton Container -- react-radio-button  (TODO: See if we can upgrade this)

	&__option-label.has-asset {
		padding-right: calc(#{$sideBySideImageSize} + 4px);
	}

	&__option-image-container {
		width: $sideBySideImageSize;
		height: $sideBySideImageSize;
	}


	&__option-label {
		display: block;
		font-family: 'Poppins', sans-serif;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		color: $color-black;
		font-size: $font-size-body-mobile;
		word-break: break-word;
		hyphens: manual;

		&.hidden {
			display: none;
		}

		@include sm() {
			font-size: $font-size-body;
		}
	}

	&__option-image {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
		background-color: #F4F4F4;
	}

	&__option-image-crop {
		object-fit: cover;
	}

	&.no-asset>div {
		flex: 1 1 100% !important; // Overriding inline styles from react-radio-button
		max-width: 100%;

		&:nth-child(odd) {
			margin-right: 0px !important;
		}
	}


	&.radio-group.image-only {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		.ranked-question__option-image-container {
			position: relative;
			right: unset;
			top: unset;
			padding-left: 6px;
			padding-right: 6px;
			width: calc(100% - 12px);
		}

		&>.radio-button {
			flex: 1 1 calc(50% - 40px) !important; // Overriding inline styles from react-radio-button
			max-width: calc(50% - 28px); // (Padding + Margin) 4 + 4 + 8  
			min-width: calc(50% - 28px); // (Padding + Margin) 4 + 4 + 8  
			margin: 0px 8px 8px 0px !important; // Overriding inline styles from react-radio-button

			// Give full with for stacked view on second column
			&:nth-child(even) {
				margin-right: 0px !important;
			}
		}

		&.has-none-of-the-above,
		&.has-other-please-specify {
			&>.radio-button {
				&:last-child {
					flex: 1 1 calc(100% - 40px) !important; // Overriding inline styles from react-radio-button
					max-width: calc(100% - 28px); // (Padding + Margin) 4 + 4 + 8  
					min-width: calc(100% - 28px); // (Padding + Margin) 4 + 4 + 8  
				}
			}
		}

		&.has-other-please-specify.has-none-of-the-above {
			&>.radio-button {

				&:nth-last-child(1),
				&:nth-last-child(2) {
					flex: 1 1 calc(100% - 40px) !important; // Overriding inline styles from react-radio-button
					max-width: calc(100% - 28px); // (Padding + Margin) 4 + 4 + 8  
					min-width: calc(100% - 28px); // (Padding + Margin) 4 + 4 + 8  
				}
			}
		}

		.ranked-question__option-image-container {
			height: 60px;

			@include sm() {
				height: 100px;
			}
		}

		.image-zoom__preview-trigger {
			opacity: 1;
			right: 0px;
			bottom: 0px;
			top: auto;
			left: auto;
			width: 18px;
			height: 18px;
			background-color: rgba(218, 218, 218, 0.5);
			border-radius: 4px;
		}
	}

	&__other-please-specify {
		display: none;
		pointer-events: none;
		touch-action: none;
		line-height: 1.15;
		height: 0px 16px;
		width: calc(100% - 36px);
		margin-left: 12px;
		margin-right: 0px;
		padding: 12px;

		&.selected {
			display: block;
		}
	}

	&__toast {
		width: 475px;
		position: fixed;
		left: 56px;
		bottom: 24px;
		z-index: 99;
		border: 1px solid $color-grey-E8;
		box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.16);
		border-radius: 10px;
		padding: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		animation: toastPopIn 0.5s ease;

		p {
			margin: 0;
			display: flex;
			align-items: center;
		}
	}

	&__close-toast-button {
		cursor: pointer;
		padding: 0;
		border: none;
		background-color: transparent;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 4px;
	}

	&__left {
		display: flex;
		gap: 16px;
	}

	@media (max-width: $screen-md-min) {
		flex-direction: column !important;
	}
}


.rtl {
	.ranked-question__option-label.has-asset {
		padding-right: 0px;
		padding-left: calc(40px + 4px);
	}

	.question-multi-selecranked-question__option-image-container {
		right: auto;
		left: 16px;
	}

	.question-multi-selecranked-question.radio-group.image-only {

		.question-multi-selecranked-question__option-image-container {
			right: auto;
			left: auto;
		}
	}

	.question {
		margin-bottom: 8px;
	}

	.ranked-question.radio-group {
		.radio-button--checkbox {
			margin: 0px 0px 0px 8px;
		}
	}

	.ranked-question {
		&.image-only {
			&>.radio-button {
				margin: 8px 0px 0px 0px !important;
			}

			&>.radio-button:nth-child(odd) {
				margin-left: 8px !important;
			}
		}
	}
}

@keyframes toastPopIn {
	0% {
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}
}