@import "variables";@import "mixins";
.emoji-button {
	width: 56px;
	height: 56px;
	border-radius: 4px;
	transition: background-color 0.3s ease-in-out 0s;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	.body.rtl & {
		margin-right: -3px;
		margin-left: 0;
	}

	&.is-checked {
		background-color: #bbe4d1;
	}

	img {
		width: 30px;
		height: 30px;
		margin: auto;
		display: flex;
	}

	&__tooltip {
		display: flex;
		justify-content: center;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
	}

}

.tooltiptext {
	width: max-content;
	background-color: #3b3b3b;
	color: #fff;
	text-align: center;
	padding: 2px 8px;
	height: 25px;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2), 0px 1px 3px rgba(0, 0, 0, 0.1);
	border-radius: 11.5px;
	position: absolute;
	top: -35px;
	display: flex;
	align-items: center;
	justify-content: center;
}
