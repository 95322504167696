@import "variables";@import "mixins";
.asset-preview-modal {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background-color: rgba(0, 0, 0, 0.3);
	&__content {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: calc(100% - 50px);
		> img {
			width: auto;
			height: 100%;
			max-height: 340px;
			border: solid 1px $color-grey-E8;
			box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.16);
		}
	}
	&__close {
		position: absolute;
		top: 16px;
		right: 16px;
		margin: 0;
		padding: 0;
		border: 0;
		background: none;
		cursor: pointer;
	}
}
