@import "variables";@import "mixins";
.link-routing-container {
	&__main {
		padding: 0 20px;
		text-align: center;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		min-height: 100vh;

		p {
			margin-bottom: 25px;

			&:focus-visible {
				outline: none;
			}
		}

		.link-routing-diagram {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.image-background {
				margin: 25px 0;
				background-color: $color-background;
				border-radius: 50%;
				width: 3rem;
				height: 3rem;
				display: flex;
				justify-content: center;
				align-items: center;

				img {
					width: 2rem;
				}
			}

			.arrow-container {
				display: flex;
				width: 15vw;
				height: 10px;
				align-items: center;
				position: relative;
				margin: 0px 3px;

				hr {
					width: 100%;
					border: 1px solid $color-blue-light;
				}

				i {
					position: absolute;
					right: 0;
				}

				.arrow {
					border: solid $color-blue-light;
					border-width: 0 2px 2px 0;
					display: inline-block;
					padding: 3px;
				}

				.right {
					transform: rotate(-45deg);
					-webkit-transform: rotate(-45deg);
				}
			}
		}
	}

    &__button-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 32px;
    }
}

.scroll-container.ios-mobile,
.scroll-container.android-mobile {
	.link-routing-container {
		height: 85vh;
		min-height: 85vh;
		max-height: 85vh;
		max-height: -moz-available;
		max-height: -webkit-fill-available;
	}
}
