@import "variables";@import "mixins";
$footerHeight: 80px; // 68px footer height + 12px padding

.grid-question {
	&__attribute-container {
		position: relative;
		cursor: default;
		margin-bottom: 14px;
		background-color: #f4f4f4;
		border-radius: 4px;

		&:focus,
		&:focus-within {
			outline: none;
		}
	}

	&__attribute-solo {
		background-color: #f4f4f4;
		border-radius: 4px;
		font-size: $font-size-header;
		line-height: $font-size-header-line-height;
		padding: 8px;
	}

	&__attribute-image {
		display: block;
		width: calc(100% - 24px);
		height: 100%;
		object-fit: contain;
		max-height: 10vh;
		padding: 12px 12px 0px 12px;

		&.image-only {
			border-radius: 4px;
		}

		@include xs {
			max-height: 22vh;
		}
	}

	&__attribute-image-crop {
		object-fit: cover;
	}

	&__sideBySide {
		display: flex;
		align-items: center;

		.grid-question__attribute-label {
			display: flex;
			flex-direction: column;
			justify-content: center;
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
			border-bottom-right-radius: 0px;
			border-top-right-radius: 0px;
		}

		.question-image {
			display: flex;
			flex: 1 1 auto;
			justify-content: space-between;
			background-color: #f4f4f4;
			border-radius: 4px;
		}

		.grid-question__attribute-image {
			border-bottom-right-radius: 4px;
			border-top-right-radius: 4px;
			border-top-left-radius: 0px;
			border-top-left-radius: 0px;
			padding: 12px;
			width: 40px;
			height: 40px;
		}
	}

	&__option-image-crop {
		object-fit: cover;
	}

	&__attribute-label {
		font-size: $font-size-header;
		line-height: $font-size-header-line-height;
		background-color: #f4f4f4;
		border-bottom-right-radius: 4px;
		border-bottom-left-radius: 4px;
		padding: 12px;
	}

	&__button-container {
		position: fixed;
		bottom: 0;
		width: 100%;
		left: 50%;
		transform: translate(-50%);
		max-width: $max-stage-width;
	}

	&__button {
		padding: 20px;
	}

	&__attribute-progress-indicator-container {
		position: relative;
		display: flex;
		width: 100%;
		height: 16px;
		border-radius: 10px;
	}

	&__attribute-progress-indicator {
		position: relative;
		height: 3px;
		align-self: flex-end;
		flex: 1 1 auto;
		border-radius: 10px;
		background-color: #e8e8e855;
	}

	&__attribute-progress-bar {
		position: relative;
		transition: all ease-in-out 1s;
		width: 10px;
		min-width: 10px;
		max-width: 100%;
		height: 100%;
		border-bottom-left-radius: 10px;
		border-top-left-radius: 10px;
		background: #2bb681;
	}

	&__attribute-progress-bar-percent {
		position: absolute;
		font-family: 'Poppins';
		font-style: normal;
		font-size: 10px;
		text-align: right;
		width: auto;
		text-align: center;
		right: 0px;
		bottom: 4px;
		margin-left: 12px;
		color: $color-black;
		transition: left ease-in-out 1s;
	}

	&__button-outer-container {
		position: relative;
	}

	&__button-container {
		position: fixed;
		bottom: 0;
		width: 100%;
		left: 50%;
		transform: translate(-50%);
		max-width: $max-stage-width;
		background: rgba(255, 255, 255, 0.5);
		backdrop-filter: blur(2px);
	}

	&__button-container-inline {
		display: flex;
		justify-content: center;
		display: -webkit-flex;
		-webkit-justify-content: center;
		z-index: 10;
		background: rgb(255, 255, 255);
		opacity: 1;
		width: 100%;

		.grid-question__button {
			background: rgb(255, 255, 255);
			padding: 15px 0px 15px 0;
			opacity: 1;
			width: 100%;
		}
	}

	&__button {
		padding: 20px;
	}

	&__other-input {
		position: absolute;
		transform: translateY(-50%); // Position other please specify center aligned on radio btn
	}
}

.scroll-container.scrolled-to-bottom {
	.basic-button:not(.disabled) {
		pointer-events: all;
	}
}

.image-zoom__asset-window.expanded {
	.grid-question__attribute-image {
		border-radius: 4px;
		padding: 0px;
		width: 100%;
		height: 100%;
	}
}
