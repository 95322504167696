@import "variables";@import "mixins";
@import 'src/styles/_variables.scss';

.text-ai-product-feedback {
	&__title {
		text-align: center;
		line-height: 24px;
		padding: 30px 0 0 0;
		font-size: 12px;
		font-weight: bold;
		margin-bottom: 0;
	}
	&__content {
		max-height: 40vh;
		max-width: 100%;
		align-items: center;
		display: flex;
		justify-content: center;
		border-bottom: $color-grey-light 1px solid;
		&.landscape {
			max-height: 200vh;
		}
	}
	&__footer {
		padding-bottom: 30px;
	}
	&__card {
		position: relative;
		max-height: 40vh;
		width: auto;
		max-width: 100%;
		&.landscape {
			max-height: 200vh;
		}
	}
	&__image-button {
		border: none;
		padding: 0;
		background-color: transparent;
		cursor: pointer;
		max-width: 100%;
		max-height: 100%;
		display: flex;
	}
	&__image {
		display: flex;
		min-width: 60%;
		margin: 0 auto;
		max-height: 40vh;
		max-width: 100%;
		z-index: 1;
		touch-action: none;
		overflow: auto;
		&.landscape {
			max-height: 200vh;
		}
	}
	&__icon {
		max-width: 60px;
		margin: 0 auto;
	}
	&__footer {
		display: flex;
		flex-direction: column;
		width: 100%;
		> div {
			width: 100%;
			padding: 15px 0;
			text-align: center;
			font-size: 12px;
			color: $color-grey;
		}
		.basic-button {
			border-radius: 20px;
			width: auto;
			height: auto;
			margin: 0 auto;
			text-transform: capitalize;
			background-color: #4bdf93;
			padding: 15px 15px;
			font-size: 12px;
			box-shadow: none;
		}
	}
	&__click-point {
		position: absolute;
		border: 2px;
		z-index: 5;
		padding: 20px;

		&:hover {
			cursor: pointer;
		}
	}
	&__click-node {
		position: relative;
		width: 15px;
		height: 15px;
		border-radius: 15px;
		background-color: $color-primary;
		border: 1px solid #fff;
		opacity: 0.7;
		&.selected {
			opacity: 1;
		}
	}
	&__tooltip {
		position: relative;
		left: 7px;
		top: -15px;
	}
}
