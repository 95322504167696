@import "variables";@import "mixins";
.authenticate-message {
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: row wrap;
	padding: 80px 30px 0;

	&__icon {
		width: 60%;
		margin-bottom: 30px;
	}

	&__title {
		font-size: 20px;
		line-height: 25px;
	}

	&__summary {
		font-size: 14px;
		line-height: 22px;
		color: $color-grey;
	}

	& div {
		display: block;
		width: 100%;
	}
}
