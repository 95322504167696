@import "variables";@import "mixins";
// &>div : ReversedRadioButton Container -- react-radio-button  (TODO: See if we can upgrade/remove this)
// &>div>div>div : Checkbox/Toggle -- react-radio-button  (TODO: See if we can upgrade/remove this)

$stackedImageSize: 100px;
$stackedImageGap: 4px;
$sideBySideImageSize: 40px;
$sideBySideImageGap: 12px;
$optionPaddingY: 12px;
$optionPaddingX: 12px;


.question-single-select {
	display: block;

	// ReversedRadioButton Container -- react-radio-button  (TODO: See if we can upgrade this)
	&>div {
		padding: $optionPaddingY $optionPaddingX !important; // Overriding inline styles from react-radio-button
		max-width: 100%;
		margin: 0px 0px 8px 0px !important; // Overriding inline styles from react-radio-button
		position: relative;
		outline: 1px solid $color-grey-DA !important; // Overriding inline styles from react-radio-button
		border: none !important; // Overriding inline styles from react-radio-button
		border-radius: 4px !important; // Overriding inline styles from react-radio-button
		background: $color-white;

		&[style*="color: rgb(40, 182, 129)"] {
			background: #E3F4EC;
		}
	}

	& > label {
		&.radio-button {
			border: 1px solid $color-grey-DA; // Overriding inline styles from react-radio-button
			outline: none; // Overriding inline styles from react-radio-button
		}
	}

	// ReversedRadioButton Container -- react-radio-button  (TODO: See if we can upgrade this)
	&>div {
		min-height: 24px;
	}

	&__option-label.has-asset {
		padding-right: calc(#{$sideBySideImageSize} + 4px);
	}

	&__option-image-container {
		position: absolute;
		padding: 0px;
		right: 16px;
		top: calc(50% - $sideBySideImageSize/2);
		width: $sideBySideImageSize;
		height: $sideBySideImageSize;
		margin: unset;
	}

	&__option-label {
		display: block;
		font-family: 'Poppins', sans-serif;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		color: $color-black;
		font-size: $font-size-body-mobile;
		word-break: break-word;
		hyphens: manual;

		&.hidden {
			display: none;
		}

		@include sm() {
			font-size: $font-size-body;
		}
	}


	&__option-image {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
		background-color: #F4F4F4;
	}

	&__option-image-crop {
		object-fit: cover;
	}

	&.no-asset>div {
		flex: 1 1 100% !important; // Overriding inline styles from react-radio-button
		max-width: 100%;
	}

	&.radio-group.image-only {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		.image-zoom__preview-container {
			max-height: 60px;
			width: fit-content;
		}

		.question-single-select__option-image-container {
			position: relative;
			right: unset;
			top: unset;
			padding-left: 6px;
			padding-right: 6px;
			width: calc(100% - 12px);
		}

		&>.radio-button {
			flex: 1 1 calc(50% - 40px) !important; // Overriding inline styles from react-radio-button
			min-width: calc(50% - 28px); // (Padding + Margin) 4 + 4 + 8  
			margin: 0px 8px 8px 0px !important; // Overriding inline styles from react-radio-button

		}

		&.has-none-of-the-above,
		&.has-other-please-specify {
			&>.radio-button {
				&:last-child {
					flex: 1 1 calc(100% - 40px) !important; // Overriding inline styles from react-radio-button
					max-width: calc(100% - 28px); // (Padding + Margin) 4 + 4 + 8  
					min-width: calc(100% - 28px); // (Padding + Margin) 4 + 4 + 8  
				}
			}
		}

		&.has-other-please-specify.has-none-of-the-above {
			&>.radio-button {

				&:nth-last-child(1),
				&:nth-last-child(2) {
					flex: 1 1 calc(100% - 40px) !important; // Overriding inline styles from react-radio-button
					max-width: calc(100% - 28px); // (Padding + Margin) 4 + 4 + 8  
					min-width: calc(100% - 28px); // (Padding + Margin) 4 + 4 + 8  
				}
			}
		}

		.question-single-select__option-image-container {
			height: 60px;
		}

		.image-zoom__preview-trigger {
			opacity: 1;
			right: 0px;
			bottom: 0px;
			top: auto;
			left: auto;
			background-color: rgba(218, 218, 218, 0.5);
			border-radius: 4px;
		}

	}



	// Checkbox/Toggle -- react-radio-button  (TODO: See if we can upgrade this)
	&>div>div>div:first-child {
		margin: 0px !important; // Overriding inline styles from react-radio-button
		min-width: 10px;
		transform: translateY(1px); // Center aligning with text
	}


	&__other-please-specify {
		display: none;
		pointer-events: none;
		touch-action: none;
		line-height: 1.15;
		height: 0px 16px;
		width: calc(100% - 32px);
		margin-left: 8px;
		margin-right: 0px;
		padding: 12px;

		&.selected {
			display: block;
		}
	}
}

.rtl {
	.question-single-select__option-label.has-asset {
		padding-right: 0px;
		padding-left: calc(40px + 4px);
	}

	.question-single-select__option-image-container {
		right: auto;
		left: 16px;
	}

	.question-single-select.radio-group.image-only {

		.question-single-select__option-image-container {
			right: auto;
			left: auto;
		}
	}

	.question {
		margin-bottom: 8px;
	}

	.question-single-select.radio-group {
		.radio-button--checkbox {
			margin: 0px 0px 0px 8px;
		}
	}

	.question-single-select {
		&.image-only {
			&>.radio-button {
				margin: 8px 0px 0px 0px !important;
			}

			&>.radio-button:nth-child(odd) {
				margin-left: 8px !important;
			}
		}
	}
}