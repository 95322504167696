@import "variables";@import "mixins";
.scroll-container.android-mobile,
.scroll-container.ios-mobile {
    .image-zoom__asset-window {
        &.expanded {
            .image-zoom__asset-window-inner {
                &>img {
                    max-height: 85vh;
                    background-color: #e8e8e8;
                }
            }
        }
    }
}

.image-zoom {
    position: relative;

    &__close {
        position: absolute;
        right: 0px;
        top: 0px;
        width: 32px;
        height: 32px;
        border-bottom-left-radius: 4px;
        color: $color-grey;
        border: 0;      
        background-color: rgba(245, 246, 248, 0.5);
        display: flex;
        display: -webkit-flex;
        justify-content: center;
        -webkit-justify-content: center;
        flex-direction: column;
        text-align: center;
        font-size: 24px;

        svg {
            margin: 0 auto;
            position: absolute;
            width: 24px;
            height: 24px;
            top: calc(50% - 12px);
            left: calc(50% - 10px);
        }

        @include sm() {
            width: 48px;
            height: 48px;

            svg {
                width: 32px;
                height: 32px;
                top: calc(50% - 16px);
                left: calc(50% - 14px);
            }
        }

    }

    &__asset-window {
        position: fixed;
        display: flex;
        -webkit-align-items: center;
        cursor: pointer;
        transition: all ease-in-out 0.25s;
        align-items: center;
        -webkit-align-items: center;
        pointer-events: none;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        scale: 0;
        opacity: 0;
        z-index: 999;
        touch-action: none;
        -moz-user-select: none;
        -webkit-user-drag: none;
        user-select: none;

        &.expanded {
            transition: all ease-in-out 0.25s;
            opacity: 1;
            scale: 1;
            pointer-events: all;

            .image-zoom__overlay {
                position: fixed;
                width: 300vw;
                height: 300vh;
                top: -100vh;
                left: -100vw;
                background-color: rgba(255, 255, 255, 0.85);
            }


            .image-zoom__asset-window-inner {
                &>img {

                    width: 100vw;
                    max-width: 640px;
                    max-height: 100vh;
                    object-fit: contain;
                    pointer-events: none;
                    background-color: transparent;
                }
            }
        }
    }

    &__asset-window-inner {
        position: relative;
        margin: 24px auto;

        &>img {
            max-height: 100vh;
            max-width: 100vw;
            object-fit: contain;
        }
    }

    &__preview-container {
        all: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 100%;

        .image-zoom__preview-trigger {
            border: 0;
            background-color: transparent;
            padding: 0;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: calc(100% - 8px);
            height: calc(100% - 8px);
            cursor: pointer;
        }
    }
}