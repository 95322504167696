@import "variables";@import "mixins";
.basic-button {
	border: none;
	color: $color-white;
	background-color: $color-green-600;
	border-radius: $border-radius-default;
	box-shadow: $button-drop-shadow;
	cursor: pointer;
	height: 38px;
	font-size: 600;
	padding: 7.5px 16px;
	font-size: 14px;
	white-space: nowrap;

	display: flex;
	align-items: center;
	justify-content: center;

	transition: background-color 0.3s ease-in-out 0s;

	&:focus {
		background-color: darken($color-green-600, 3%);
		box-shadow: $button-drop-shadow;
		outline: 0;
	}

	&:hover {
		color: $color-white;
		background-color: darken($color-green-600, 3%);
	}

	&:active {
		background-color: darken($color-green-600, 5%);
		box-shadow: $button-drop-shadow-hover;
		outline: none;
		border: none;
		transform: $transform-depress;
	}

	&.secondary {
		color: $color-grey-6c;
		background-color: transparent;
		box-shadow: none;

		&:focus,
		&:hover {
			background-color: $keyline-button-hover-background;
		}

		&:active {
			background-color: darken($keyline-button-hover-background, 5%);
		}
	}

	&.danger {
		background-color: $color-error;

		&:focus,
		&:hover {
			background-color: $color-error-hover;
		}

		&:active {
			background-color: darken($color-error-hover, 5%);
		}
	}

	&.bold {
		font-weight: 600;
	}

	&.full-width {
		width: 100%;
	}

	&.loading {
		pointer-events: none;
		color: transparent !important;
		cursor: progress;
	}

	&.loading::after {
		-webkit-animation: spin-loader 1000ms infinite linear;
		animation: spin-loader 1000ms infinite linear;
		border: 2px solid $color-white;
		border-radius: 290486px;
		border-right-color: transparent;
		border-top-color: transparent;
		content: '';
		display: block;
		height: 16px;
		width: 16px;
	}

	&.loading::after,
	&.loading::before {
		box-sizing: inherit;
	}

	&.disabled {
		opacity: 0.5;
		pointer-events: none;
		cursor: not-allowed;
	}
}

@-webkit-keyframes spin-loader {
	from {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

@keyframes spin-loader {
	from {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}
