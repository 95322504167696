@import "variables";@import "mixins";
.modal-permission-settings {
	&__modal-overlay {
		background-color: #0000004d;
		position: fixed;
		top: 0px;
		left: 0px;
		z-index: 9;
		width: 100vw;
		height: 100vh;
		@media (max-width: 640px) {
			background-color: #0000009d;
		}
	}
	&__modal-permission {
		display: flex;
		position: fixed;
		width: 35%;
		// height: min-content;
		max-width: 780px;
		background-color: $color-white;
		box-shadow: 0px 5px 12px 0px #00000029;
		border-radius: 10px;
		z-index: 10;
		align-items: flex-start;
		flex-direction: column;
		left: 33vw;
		@media (max-width: 640px) {
			margin-top: 40px;
			top: 2vh;
		}
	}

	&__modal-setting-img {
		background-color: $color-green-very-light;
		padding: 20px;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
		height: 248px;
		display: flex;
		@media (max-width: 850px) {
			> img {
				width: 300px;
			}
		}
	}
	&__modal-container {
		padding: 20px;
		height: 100%;
		display: flex;
		flex-direction: column;
		width: calc(100% - 40px);
	}
	&__modal-header {
		display: flex;
		margin-bottom: 12px;
		justify-content: space-between;
	}
	&__modal-header-mobile {
		display: flex;
		margin-bottom: 16px;
		margin-top: 24px;
		justify-content: center;
	}
	&__modal-header-icon {
		display: flex;
		margin-bottom: 24px;
		justify-content: center;
		align-items: center;
		width: 100%;
		> img {
			width: 54px;
			margin-right: 8px;
		}
	}
	&__icon-button {
		padding: 0;
		border: none;
		background-color: transparent;
		border-radius: 4px;

		> img {
			width: 12px;
			height: 17px;
			margin-top: 2px;
			cursor: pointer;
		}
	}
	&__modal-title {
		display: flex;
		font-size: 17px;
		font-weight: 600;
		line-height: 25.5px;
		text-align: left;
	}
	&__modal-title-system {
		display: flex;
		font-size: 17px;
		font-weight: 600;
		line-height: 25.5px;
		text-align: left;
	}
	&__modal-title-mobile {
		display: flex;
		font-size: 17px;
		font-weight: 600;
		line-height: 25.5px;
		text-align: center;
	}
	&__modal-content {
		display: flex;
		flex-direction: column;
		> span {
			font-size: 14px;
			font-weight: 400;
			line-height: 21px;
			text-align: justify;

			> span {
				font-size: 14px;
				font-weight: 600;
				line-height: 21px;
				text-align: justify;
				color: $color-green-600;
			}
		}
	}
	&__modal-footer {
		display: flex;
		width: 100%;
		justify-content: flex-end;
		align-items: center;
		gap: 8px;
		margin-top: 25px auto 0;
	}

	&__modal-button {
		width: 76px;
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	&__modal-button-secondary {
		padding: 8px;
		width: 108px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		border: 1px solid $color-green-600;
		border-radius: 6px;
	}
	&__modal-button-primary {
		padding: 8px;
		width: 71px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		background-color: $color-green-600;
		border-radius: 6px;
	}

	&__modal-reject {
		font-size: 14px;
		font-weight: 600;
		line-height: 21px;
		text-align: left;
	}
	&__modal-audio {
		font-size: 14px;
		font-weight: 600;
		line-height: 21px;
		text-align: left;
		color: $color-green-600;
	}
	&__modal-allow {
		font-size: 14px;
		font-weight: 600;
		line-height: 21px;
		text-align: left;
		color: $color-white;
	}

	&__modal-text {
		display: flex;
		flex-direction: column;
		font-size: 14px;
		font-weight: 400;
		line-height: 21px;
		text-align: left;
	}
	&__modal-text-mobile {
		display: flex;
		flex-direction: column;
		font-size: 14px;
		font-weight: 400;
		line-height: 21px;
		text-align: center;
	}
	&__modal-text-step1 {
		display: inline-flex;
		font-size: 14px;
		font-weight: 400;
		line-height: 21px;
		text-align: left;
		align-items: center;
		flex-wrap: wrap;
	}
	&__modal-text-item {
		margin-top: 8px;
	}
	&__modal-text-number {
		padding-left: 16px;
		margin-top: 0px;
		margin-bottom: 0px;
	}
	&__url-bar {
		margin-top: 15px;
	}
	&__safari-url-bar {
		margin-top: 15px;
	}
	&__ios-setting-banner {
		margin-top: 15px;
	}
	&__setting-banner {
		width: 333px;
		margin-top: 15px;
	}
	&__modal-text-step2 {
		margin-top: 8px;
	}
	&__modal-text-step3 {
		margin-top: 8px;
	}

	&__img-setting {
		margin-left: 4px;
		margin-right: 4px;
	}
	&__img-bar {
		position: relative;
		left: -11px;
	}
	&__locked-container {
		width: 100%;
		display: flex;
		justify-content: center;
		margin-bottom: 8px;
	}
	&__locked {
		width: 54px;
	}

	@media (max-width: 640px) {
		&__modal-button {
			width: 100%;
			height: 28px;
		}
		&__modal-button-primary {
			width: 100%;
			height: 28px;
		}
		&__modal-button-secondary {
			width: 100%;
			height: 28px;
		}
		&__modal-footer {
			margin-top: 28px;
			flex-direction: column;
		}
		&__modal-container {
			height: unset;
		}
	}

	@media (max-width: 770px) {
		&__modal-permission {
			width: 420px;
			left: 18vw;
			top: 10vh;
		}
		&__modal-container {
			padding: 16px;
		}
		&__modal-title {
			font-size: 14px;
			text-align: center;
		}
		&__modal-text-step1 {
			width: 100%;
		}
		&__setting-banner {
			max-width: 90%;
		}
		&__url-bar {
			max-width: 90%;
		}
	}
	@media (max-width: 490px) {
		&__modal-permission {
			width: 90%;
			left: 24px;
		}
		&__modal-container {
			padding: 16px;
		}
		&__modal-title {
			font-size: 14px;
			text-align: center;
		}
		&__modal-text-step1 {
			width: 100%;
		}
		&__setting-banner {
			max-width: 90%;
		}
		&__url-bar {
			max-width: 90%;
		}
	}

	@media (max-width: 295px) {
		&__modal-permission {
			left: 15px;
		}

		&__modal-text-step1 {
			font-size: 12px;
		}
		&__modal-text-step2 {
			font-size: 12px;
		}
		&__modal-text {
			font-size: 12px;
		}
	}
}
