@import "variables";@import "mixins";
.question-label {
	line-height: 24px;
	word-break: break-word;
	hyphens: manual;

	&:focus, &:focus-visible {
		outline: none;
	}

	&__pill {
		display: inline-flex;
		vertical-align: middle;
		align-items: center;
		background-color: $color-blue-text-bg;
		max-width: calc(100% - 12px);
		border-radius: 4px;
		line-height: 24px;
		padding: 1px 4px;
		margin-bottom: 2px;

		&-text {
			margin-inline-start: 4px;
		}

		&-emoji {
			@extend .question-label__pill;
			text-transform: capitalize;

			&-image {
				height: 18px;
				width: 18px;
				margin-right: 6px;
				vertical-align: middle;
			}
		}
	}

	&__empty-state {
		background-color: $color-black;
		height: 2px;
		width: 48px;
		margin: 0px 4px;
		display: inline-block;
	}

	&__preview-photo-icon {
		display: flex;
		border-radius: 4px;
		min-width: 24px;
		max-width: 24px;
		min-height: 24px;
		max-height: 24px;
		background-color: $color-grey-light;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		cursor: pointer;
		border: 1px solid $color-grey-light;
		overflow: hidden;
	}
}