@import "variables";@import "mixins";
.modal-are-you-sure {
	&__modal-overlay {
		background-color: #0000004d;
		position: fixed;
		top: 0px;
		left: 0px;
		z-index: 2;
		width: 100vw;
		height: 100vh;
	}

	&__modal-container {
		display: flex;
		position: fixed;
		padding: 16px 20px;
		max-width: 500px;
		background-color: #fff;
		box-shadow: 0px 5px 12px 0px #00000029;
		border-radius: 10px;
		z-index: 10;
		align-items: flex-start;
		flex-direction: column;
	}

	&__modal-header {
		display: flex;
		width: 100%;
		position: relative;
		// height: 70px;
	}

	&__modal-header-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		> img {
			width: 54px;
			margin-right: 8px;
		}
	}

	&__icon-button {
		padding: 0;
		border: none;
		background-color: transparent;
		border-radius: 4px;
		position: absolute;
		right: 0px;

		> img {
			width: 12px;
			height: 17px;
			margin-top: 2px;
			cursor: pointer;
		}
	}

	&__modal-content {
		display: flex;
		flex-direction: column;
		width: 100%;
		justify-content: center;
		align-items: flex-start;
	}

	&__modal-title {
		display: flex;
		align-items: center;
		font-size: 17px;
		font-style: normal;
		font-weight: 600;
		line-height: 26px;
		text-align: center;
	}

	&__modal-text {
		font-size: 14px;
		font-weight: 400;
		line-height: 21px;
		text-align: left;
		margin-top: 32px;
		margin-bottom: 16px;

		> span {
			color: #005832;
			font-weight: 600;
		}
	}

	&__modal-footer {
		display: flex;
		width: 100%;
		justify-content: flex-end;
		align-items: center;
		gap: 8px;
		margin-top: 16px;
	}

	&__modal-button {
		width: 76px;
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		cursor: pointer;
	}

	&__modal-button-secondary {
		padding: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		background-color: #ff3c41;
		border-radius: 6px;
	}

	&__modal-button-primary {
		padding: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		background-color: #ffffff00;
		border-radius: 6px;
	}

	&__modal-reject {
		font-size: 14px;
		font-weight: 600;
		line-height: 21px;
		text-align: left;
		color: $color-white;
	}

	&__modal-allow {
		font-size: 14px;
		font-weight: 600;
		line-height: 21px;
		text-align: left;
	}
	&__modal-button-primary-audio {
		padding: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		background-color: #28b681;
		border-radius: 6px;
	}
	&__modal-text-container {
		display: flex;
		flex-direction: column;
	}
	&__list-rules {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 12px;
	}
	&__rule-item {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 12px;
		border-radius: 8px;
		border: 1px solid $color-grey-E8;
		background: $color-white;
		padding: 16px;
		max-width: 426px;
	}
	&__rule-text {
		font-size: 14px;
		line-height: 21px;
		text-align: left;
	}
	&__rule-image {
		width: 32px;
	}
	@media (max-width: 768px) {
		&__modal-footer {
			flex-direction: column;
		}
		&__modal-button-secondary {
			width: 100%;
			height: 28px;
		}
		&__modal-button-primary {
			width: 100%;
			height: 28px;
		}
	}
	@media (max-width: 400px) {
		&__modal-container {
			width: 85%;
			top: 26px;
		}
		&__modal-title {
			font-size: 15px;
			text-align: center;
		}
		&__modal-text {
			width: 90%;
			text-align: left;
			margin-top: 12px;
		}
	}
	@media (max-width: 295px) {
		&__modal-title {
			font-size: 14px;
		}
		&__modal-text {
			width: 94%;
			margin-top: 0px;
			font-size: 13px;
		}
		&__rule-item {
			padding: 12px;
		}
	}
}
