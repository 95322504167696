@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');

body {
	font-weight: 400;
	font-family: 'Poppins', sans-serif;
	color: $color-black;
	-webkit-tap-highlight-color: transparent;
	background: linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
}

a,
a:hover,
a:link,
a:visited {
	color: $color-primary;
	text-decoration: none;

	&:hover {
		opacity: 0.7;
	}
}

* {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: -moz-none;
	-o-user-select: none;
	user-select: none;
	-webkit-overflow-scrolling: touch;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
	font-weight: 600;
}

h2,
h3 {
	font-size: 15px;
	line-height: 1.8;
	margin: 0;
}

p {
	font-size: 14px;
}

// * Apply keyboard navigation outline only to inherently focusable elements or those with `tabindex` set
input,
button,
select,
textarea,
a[href],
[tabindex],
[contenteditable='true'] {
	&:not([tabindex='-1']) {
		&:focus-visible {
			outline: 2px solid $color-primary; /* Visible outline for focused element */
			outline-offset: 2px;
		}
	}
}

// * Hidden `h1` for the accessibility best practices
.accessibility-h1 {
	pointer-events: none;
	position: absolute;
	top: -1px;
	left: -1px;
	width: 1px;
	height: 1px;
	overflow: hidden;
	z-index: -1;
	opacity: 0;
}
