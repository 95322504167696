@import "variables";@import "mixins";
.ai-banner {
	&__container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		margin-top: auto;
	}
	&__ai-question-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		max-width: 600px;
	}
	&__left-side-container {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: $color-grey-FA;
	}

	&__ai-container {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		border-radius: 12px;
		background: $color-grey-E8;
		box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
		padding: 20px;
		gap: 12px;
	}

	&__ai-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: $color-white;
		width: 240px;
		height: 350px;
		border-radius: 8px;
	}
	&__header {
		display: flex;
		margin-bottom: 8px;
		justify-content: space-between;
		> img {
			width: 12px;
			height: 17px;
			cursor: pointer;
		}
	}

	&__title {
		display: flex;
		font-size: 17px;
		font-weight: 600;
		line-height: 25.5px;
		text-align: left;
	}
	&__ai-header {
		display: flex;
		margin-bottom: 24px;
	}

	&__ai-title {
		text-align: center;
		font-size: 22px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		letter-spacing: 0.6px;
		text-transform: uppercase;
		color: $color-black;
	}
	&__content {
		display: flex;
		margin-bottom: 32px;
		flex-direction: row;
	}
	&__desc {
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 21px;
		color: $color-grey-6c;
		text-align: center;
		padding: 50px;
		padding-top: 0px;
		padding-bottom: 0px;
		max-width: 463px;
	}

	&__ai-listening {
		color: #11bf88;
		text-align: center;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px;
		margin-top: 40px;
	}
	&__tell-us {
		color: $color-grey-6c;
		text-align: center;
		font-size: 17px;
		font-style: normal;
		line-height: 26px;
		margin-top: 70px;
		width: 176px;
	}

	&__footer {
		display: flex;
		flex-direction: column;
		width: 100%;
		justify-content: flex-end;
		align-items: center;
		gap: 8px;
	}

	&__button {
		padding: 8px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		border-radius: 6px;
		max-width: 463px;
		&:hover {
			background-color: $keyline-button-hover-background;
		}
	}

	&__button-primary {
		padding: 8px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		background-color: #28b681;
		border-radius: 6px;
		max-width: 463px;
		&:hover {
			background-color: #5bc397;
		}
	}

	&__reject {
		font-size: 14px;
		font-weight: 600;
		line-height: 21px;
		text-align: left;
		box-shadow: none;
		color: $color-grey-6c;
	}
	&__allow {
		font-size: 14px;
		font-weight: 600;
		line-height: 21px;
		text-align: left;
		color: $color-white;
	}

	&__disclaimer-container {
		display: flex;
		margin-top: 260px;
		margin-bottom: 32px;
		max-width: 484px;
	}
	&__disclaimer-text {
		color: #a7a7a7;
		font-size: 10px;
		font-style: normal;
		font-weight: 400;
		line-height: 15px;
		text-align: center;
	}

	@media (max-width: 1120px) {
		max-width: 100%;
		flex-direction: column-reverse;
		justify-content: flex-end;

		&__header {
			margin-bottom: 12px;
			max-width: 320px;
		}
		&__button-primary {
			max-width: 300px;
		}
		&__content {
			width: 333px;
		}
		&__ai-content {
			width: 140px;
			height: 205px;
		}
		&__ai-listening {
			font-size: 9px;
		}
		&__tell-us {
			font-size: 9px;
			width: 100%;
			margin-top: 40px;
		}
		&__disclaimer-container {
			margin-top: 24px;
			padding: 0px 16px;
		}
		&__desc {
			padding: 0px;
		}
		&__title {
			margin-top: 24px;
		}
		&__left-side-container {
			max-height: 333px;
			margin-top: 10px;
		}
		&__container {
			margin-top: 0px;
		}
	}
	&__smily-lady {
		width: 100%;
	}
	@media (max-width: 295px) {
		&__tell-us {
			width: 80%;
			line-height: 12px;
			margin-top: 12px;
		}
		&__ai-container {
			width: 245px;
			padding: 16px;
		}
		&__ai-content {
			height: 166px;
		}
		&__ai-question-container {
			margin-top: 20px;
		}
	}
}
