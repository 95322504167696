@import "variables";@import "mixins";
$previewStudyBannerHeight: 50px;

.device-preview-header {
	display: flex;
	position: fixed;
	background: white;
	min-height: $previewStudyBannerHeight;
	width: 100%;
	box-sizing: border-box;
	padding: 10px 40px;
	text-align: center;
	justify-content: center;
	align-items: center;
	z-index: 999;
	box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.16);
	-moz-box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.16);
	-webkit-box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.16);

	+ .scroll-container .scroll-container__inner {
		margin-top: calc(#{$previewStudyBannerHeight} - 10px); // Banner preview height - 10px padding
	}

	&__hidden {
		display: flex;
		position: fixed;
		background: white;
		min-height: $previewStudyBannerHeight;
		width: 100%;
		box-sizing: border-box;
		padding: 10px 40px;
		text-align: center;
		justify-content: center;
		align-items: center;
		z-index: 1000;
		box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.16);
		-moz-box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.16);
		-webkit-box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.16);
	
		opacity: 1;
	
		&.disabled {
			opacity: 0;
			pointer-events: none;
	
			* {
				transition: opacity 1s ease-in-out 0s;
				opacity: 0;
			}
		}
	}

	&__text {
		color: #3b3b3b;
		font-size: 14px;
		margin: 0;
		display: flex;
	}

	&__close {
		position: absolute;
		color: #666666;
		top: 0;
		right: 0;
		background: none;
		border: none;
		width: $previewStudyBannerHeight;
		height: $previewStudyBannerHeight;
		cursor: pointer;

		&:focus {
			outline: none;
		}
	}
}
