@import "variables";@import "mixins";
.scroll-container__inner--rtl {
	max-width: 100% !important;
	@media (max-width: 295px) {
		padding-top: 0px !important;
	}
}
.video-checker {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: center;
	height: 100vh;
	margin: auto;

	@media (max-width: 800px) {
		max-width: 100%;
		flex-direction: column-reverse;
		justify-content: flex-end;
	}
}
