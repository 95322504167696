@import "variables";@import "mixins";
.main-container-end {
  &__loader {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 200px;
  }
}
