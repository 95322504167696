@import "variables";@import "mixins";
.footer-helper {
	position: fixed;
	bottom: 0;
	width: 100%;
	left: 50%;
	transform: translate(-50%);
	max-width: $max-stage-width;
	max-height: 68px;
	background: rgba(255, 255, 255, 0.5);
	backdrop-filter: blur(2px);
}
