@import "variables";@import "mixins";
// TODO: Figure out how to lose the !importants... nothing is this !important

@import 'src/styles/_variables.scss';

// Overrides the styles on the survey battleground component
.text-ai-card {
    .product-card-item {
        position: relative;
    }
    .card-view-product {
        transition: all 0.5s;
        width: 100% !important;
        &.expanded {
            margin: auto;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            overflow: hidden;
            max-height: none !important;
        }
    }
    &.expanded {
        .card-view-product {
            box-shadow: none;
            pointer-events: none;
        }
        .card-view-product,
        .new-card-section-image {
            height: 100px !important;
            max-height: 100vh !important;
        }
        .new-card-section-details {
            display: none;
        }
    }
}
