@import "variables";@import "mixins";
.ranked-question-banner {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 32px);
  height: fit-content;
  background: $color-white;
  z-index: 4;
  padding: 12px 16px;
  box-shadow: 0px 15px 10px -15px $color-grey;

  &__progress-container {
    display: flex;
    gap: 8px;
  }

  &__progress-bar {
    width: 92%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__progress-count {
    width: 8%;
    font-size: 10px;
  }

  &__grey-bar {
    width: 100%;
    height: 4px;
    background-color: $color-grey-E8;
    content: "";
    border-radius: 10px;
  }

  &__green-bar {
    position: absolute;
    left: 0;
    transition: 0.25s ease;
    height: 4px;
    background-color: $color-green;
    border-radius: 10px;
    content: "";
  }
}