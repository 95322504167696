@import "variables";@import "mixins";
// Need to do this to enable typing in text areas on IOS devices
input, textarea {
	-webkit-user-select: text;
	-khtml-user-select: text;
	-moz-user-select: text;
	-ms-user-select: text;
	user-select: text;
}

.product-description-form {
	position: relative;
	margin: 0 15px;
	overflow: hidden;
	&__edit:link {
		position: absolute;
		right: 50px;
		top: 15px;
		color: $color-grey-A9;
		font-size: 10px;
		text-decoration: underline;
	}
	&__textarea {
		// General style
		font-size: 14px;
		line-height: 21px;
		font-weight: 100;
		border: none;
		color: $color-grey-53;
		background-color: $color-white-f7;
		width: 100%;

		// TextArea specific styles
		resize: none;
		outline: none;

		// 'box-sizing: border-box' so padding doesn't add width to the textarea component
		box-sizing: border-box;
		padding: 10px 40px 0 15px;

		// Setup height animation
		height: 40px;
		border-radius: 30px;
		transition: height 0.5s, border-radius 0.5s;

		// Formatting the copy the user has written in the textarea
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
	&__character-count {
		display: flex;
    	flex-direction: column;
		position: absolute;
		bottom: 4px; // TODO: Shouldn't have to do this, but am having to account fo the height of the green progress bars
		left: 0px;
		width: 100%;
		box-sizing: border-box;
		padding: 15px;
	}
	&__character-count-text {
		width: 100%;
		color: $color-grey-DA;
		font-size: 12px;
		text-align: right;
		padding-right: 4px;
		margin-bottom: 10px;
    	box-sizing: border-box;
	}
	&__character-count-progress {
		display: flex;
		// TODO: Fix the 4px space on either side of the green bars preventing this them from being flush
		justify-content: space-between;
	}
	&__character-count-marker {
		width: 20%;
		height: 4px;
		margin: 0 4px;
		box-sizing: border-box;
		background: $color-grey-DA;
	}
	&__character-count-marker.show {
		background: $color-like;
	}
	&__submit {
		background: $color-grey-DA;
		border-radius: 50%;
		border: none;
		width: 30px;
		height: 30px;
		color: $color-white;
		position: absolute;
		top: 5px;
		right: 5px;
		padding: 0px;
		margin: 0px;
		outline: none;
	}
	&__submit.enabled {
		background: $color-like;
	}
}
.product-description-form__textarea.expanded {
	height: 126px; // Height to animate to
	border-radius: 4px; // Border radius to animate to
	white-space: normal;
}